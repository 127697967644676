import { Table, Dropdown, Form } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { toast } from "react-toastify";
import { useState, useEffect, useRef } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import { CSVLink } from "react-csv";
import { format } from "date-fns";

import { fetchActionsUtil } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import ConfirmDialog from "../ConfirmDialogue";
import PasswordModal from "../modals/ChangePassword";
import EditUserAccountModal from "../modals/EditUserAccount";
import "../../assets/scss/reports/cashbook.scss";
import { ExchangeFunds, SearchIcon } from "../Icons";
import CreateUserAccount from "../modals/CreateUserAccount";
import EditUserPriviledgeModal from "../modals/EditUserPriviledgeModal";
import { useQueryParams } from "../../utils/hooks";
import useDebounce from "../../utils/hooks";
import { CustomInput } from "../utils/CustomInput";
import NoTableItem from "../utils/NoTableItem";
import { paginationOptions } from "../../utils/helpers";
import TableComponent from "../TableComponent";
import { ExportIcon, ExcelIcon } from "../Icons";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";

const UserManagemnt = () => {
  const initialFilterParams = {
    q: "",
  };

  const { backendUrl } = useAuth();
  const [passwordModal, setPasswordModal] = useState({
    modal: false,
    name: "",
    userId: "",
  });

  const [editUserModal, setEditUserModal] = useState({
    modal: false,
    name: "",
    userId: "",
  });

  const [
    showEditUserPriviledgesModal,
    setShowEditUserPriviledgesModal,
  ] = useState(false);

  const [openCreateUser, setOpenCreateUser] = useState(false);

  const [currentStatus, setCurrentStatus] = useState("");

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams((qr) => ({ ...qr, ...debouncedFilterParams }));
  }, [debouncedFilterParams, setQueryParams]);

  const { data, refetch } = useQuery(
    ["FETCH STAFF", queryParams],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/users?&${queryString.stringify(queryParams)}`,
        "GET"
      ),
    {
      keepPreviousData: true,
    }
  );

  const lockAccount = useMutation(
    (payload) =>
      fetchActionsUtil(`${backendUrl}/api/users/status`, "POST", "", payload),
    {
      onSuccess: () => {
        refetch();
        toast.success(`Account ${currentStatus} Successfully`);
      },
      onError: () => {
        toast.error("Unable to lock account");
      },
    }
  );

  const deleteAccount = useMutation(
    (payload) =>
      fetchActionsUtil(`${backendUrl}/api/users/${payload}`, "DELETE"),
    {
      onSuccess: () => {
        refetch();
        toast.success("Account deleted Successfully");
      },
      onError: () => {
        toast.error("Unable to delete");
      },
    }
  );

  const onLockAccount = async (Staff_ID, name, status) => {
    const Status = status === "Lock" ? "Open" : "Lock";
    const Distatus = status === "Lock" ? "Unblock" : "block";
    setCurrentStatus(`${Distatus}ed`);
    if (
      await ConfirmDialog({
        title: `${Status} Account`,
        description: `Are you sure you want to ${Distatus.toLocaleUpperCase()} ${name}'s Account`,
      })
    ) {
      lockAccount.mutate({ Staff_ID, Status });
    }
  };

  const onDeleteAccount = async (staffID, name) => {
    if (
      await ConfirmDialog({
        title: "Delete Account",
        description: `Are you sure you want to DELETE ${name}'s Account`,
      })
    ) {
      deleteAccount.mutate(staffID);
    }
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const displayTableData = (el, index) => {
    return (
      <>
        <td>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="bg-white border-0"
              bsPrefix="print more"
            >
              <DotsVerticalIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
              className="dropdown-with-icons"
            >
              <Dropdown.Item
                as="div"
                onClick={() =>
                  setEditUserModal({
                    userId: el.Staff_ID,
                    modal: true,
                    name: el.Name,
                  })
                }
                className="p-cursor"
              >
                {`Edit ${el.Name} Account`}
              </Dropdown.Item>
              <Dropdown.Item
                as="div"
                onClick={() => onDeleteAccount(el.Staff_ID, el.Name)}
                className="p-cursor"
              >
                {`Delete ${el.Name} Account `}
              </Dropdown.Item>
              <Dropdown.Item
                as="div"
                onClick={() => {
                  setEditUserModal({
                    userId: el.Staff_ID,
                    modal: false,
                    name: el.Name,
                  });
                  setShowEditUserPriviledgesModal(true);
                }}
                className="p-cursor"
              >
                {`Edit ${el.Name} Priviledges`}
              </Dropdown.Item>
              <Dropdown.Item
                as="div"
                onClick={() =>
                  setPasswordModal({
                    userId: el.Staff_ID,
                    modal: true,
                    name: el.Name,
                  })
                }
                className="p-cursor"
              >
                {`Change ${el.Name} Password`}
              </Dropdown.Item>
              <Dropdown.Item
                as="div"
                onClick={() => onLockAccount(el.Staff_ID, el.Name, el.Status)}
                className="p-cursor"
              >
                {`${el.Status === "Lock" ? "Unblock" : "Block"} ${
                  el.Name
                } Account`}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
        <td>{el.Staff_ID}</td>
        <td>{el.Title}</td>
        <td>{el.Name}</td>
        <td>{el.username}</td>
        <td>{el.Department}</td>
        <td>{el.Branch}</td>
        <td>{el.AccessLavel}</td>
        <td
          className={`${
            el.Status === "Lock" ? "text-danger" : "text-success"
          } fw-bold`}
        >
          {el.Status === "Lock" ? "Blocked" : "Active"}
        </td>
      </>
    );
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th />
          <th>Staff ID</th>
          <th>Title</th>
          <th>Full Name</th>
          <th>User Name</th>
          <th>Department</th>
          <th>Branch</th>
          <th>Access Level</th>
          <th>Status</th>
        </tr>
      </thead>
    );
  };

  const onDownloadExcelData = async () => {
    const { limit, page, ...rest } = queryParams;
    let exData = await fetchExcelData(
      `${backendUrl}/api/users?${queryString.stringify(rest)}`,
      "GET"
    );

    exData = exData?.staff?.map((d) => [
      d.Staff_ID,
      d.Title,
      d.Name,
      d.username,
      d.Department,
      d.Branch,
      d.AccessLavel,
      d.Status === "Lock" ? "Blocked" : "Active",
    ]);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [""],
      ["All Users"],
      [date],
      [""],
      [
        "Staff ID",
        "Title",
        "Full Name",
        "User Name",
        "Department",
        "Branch",
        "Access Level",
        "Status",
      ],
      ...exData,
      [""],
    ];
    // console.log(exData);
    setExcelData(exData);
  };

  return (
    <div className="dashboard-content">
      <div className=" pt-2 bg-white">
        <main className="table-sticky-header">
          <div className="">
            <header className="container px-4 my-3 d-flex justify-content-between">
              <div className="d-flex align-items-baseline">
                <h6>User List</h6>
                <p className="mx-3">
                  {data?.count} of {data?.company?.Num_Users}
                </p>
                <button
                  title="Refresh"
                  onClick={() => refetch()}
                  className="btn text-primary"
                >
                  <CachedIcon />
                </button>
              </div>

              <div className="d-flex ">
                <CSVLink
                  className="btn print d-none"
                  filename={`Users (${format(
                    new Date(),
                    "dd-MMM-yyyy hh:mm:ss a"
                  )}).csv`}
                  data={excelData}
                  ref={CSVLinkRef}
                />
                <Dropdown>
                  <Dropdown.Toggle
                    variant=""
                    className="btn print"
                    disabled={isfetchingExcel}
                    bsPrefix=""
                  >
                    Export
                    <ExportIcon color="#008000" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    popperConfig={{
                      strategy: "fixed",
                    }}
                    renderOnMount
                    className="text-center"
                  >
                    <Dropdown.Item
                      as="div"
                      onClick={onDownloadExcelData}
                      className="p-cursor"
                    >
                      Excel <ExcelIcon color="#008000" />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Form.Group className="mx-3">
                  <div className="input-group">
                    <>
                      <CustomInput
                        className="p-cursor"
                        name="q"
                        placeholder="Search name..."
                        typeOfInput="text"
                        value={filterParams?.q}
                        onChange={(e) => handleFilterParamsChange(e)}
                      />

                      <span
                        className="input-group-text border p-cursor"
                        id="basic-addon1"
                      >
                        <SearchIcon />
                      </span>
                    </>
                  </div>
                </Form.Group>
                <button
                  onClick={() => setOpenCreateUser(true)}
                  className="btn bg print text-black"
                >
                  Create New <ExchangeFunds color="black" />
                </button>
              </div>
            </header>

            <div className="px-md-4">
              {data?.staff?.length > 0 && (
                <TableComponent
                  responsive
                  borderless
                  striped
                  tableHeadsFunction={tableHead}
                  mainDataArray={data?.staff}
                  tableDataRowFunction={displayTableData}
                  className="product-table"
                />
              )}
              {data?.staff?.length === 0 && <NoTableItem />}
            </div>
          </div>
        </main>
        <div className="d-flex justify-content-between px-3 align-items-center pagination py-4">
          <div className="pagination_left">
            {/* <span className="m-0 p-0">Show</span> */}
            <select
              value={queryParams.limit}
              name="limit"
              className="form-select "
              onChange={(e) => handleSearchQueryChange(e)}
            >
              <option value="10">10 rows</option>
              <option value="20">20 rows</option>
              <option value="30">30 rows</option>
              <option value="40">40 rows</option>
              <option value="50">50 rows</option>
              <option value="100">100 rows</option>
            </select>
          </div>

          <ReactPaginate
            {...paginationOptions}
            pageCount={Math.ceil(data?.count / queryParams.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={0}
            onPageChange={({ selected }) => {
              document.body.scrollTop = document.documentElement.scrollTop = 0;
              setQueryParams({
                ...queryParams,
                page: selected + 1,
              });
            }}
            forcePage={parseInt(queryParams.page) - 1}
          />
        </div>
      </div>

      {passwordModal.modal && (
        <PasswordModal
          onHide={() => setPasswordModal({ ...passwordModal, modal: false })}
          show={passwordModal.modal}
          modalName={passwordModal.name}
          userId={passwordModal.userId}
        />
      )}

      {showEditUserPriviledgesModal && (
        <EditUserPriviledgeModal
          showEditUserPriviledgesModal={showEditUserPriviledgesModal}
          setShowEditUserPriviledgesModal={setShowEditUserPriviledgesModal}
          userId={editUserModal.userId}
          userName={editUserModal.name}
        />
      )}

      {editUserModal.modal && (
        <EditUserAccountModal
          onHide={() => setEditUserModal({ ...editUserModal, modal: false })}
          show={editUserModal.modal}
          modalName={editUserModal.name}
          userId={editUserModal.userId}
          refetch={refetch}
        />
      )}
      {openCreateUser && (
        <CreateUserAccount
          onHide={() => setOpenCreateUser(false)}
          show={openCreateUser}
          refetch={refetch}
          setShowEditUserPriviledgesModal={setShowEditUserPriviledgesModal}
        />
      )}
    </div>
  );
};

export default UserManagemnt;
