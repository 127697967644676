import { FieldArray, useFormik, FormikProvider } from "formik";
import React, { useState, useMemo } from "react";
import Button from "react-bootstrap/Button";
import { Modal, Form, Nav, InputGroup, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import * as yup from "yup";
import EyeOffOutline from "mdi-react/EyeOffOutlineIcon";
import EyeOutline from "mdi-react/EyeOutlineIcon";

import useDebounce, {
  useBackendUrl,
  useEffectOnce,
  useIsAdmin,
  useIsDocumentControl,
} from "../utils/hooks";
import Avatar from "./utils/Avatar";
import { useAuth } from "../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import { DeleteIcon, GearIcon } from "./Icons";
import { useMutation, useQuery } from "react-query";
import { amountTypes, initialGeneralSettings } from "../utils/helpers";
import Select from "react-select";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import ExpensesSelector from "./utils/ExpensesSelector";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Popover } from "react-tiny-popover";
import eventBus from "../utils/EventBus";
import { isEmpty, uniqBy } from "lodash";
import { cacheTimes, queryActions } from "../utils/reactQueryActions";
import NumberCustomInput from "./utils/NumberCustomInput";
import { durationOptions } from "../config";

const limitOptions = [
  {
    label: "Fixed",
    value: "fixed",
  },
  {
    label: "Any Amount",
    value: "any",
  },
];

function StaffSelectorMultiple({
  data,
  index,
  name,
  formik,
  arrayHelpers,
  hasLimit,
}) {
  const staffSelectOptions = useMemo(() => {
    const jobGrades = data.staffs
      .filter((el) => el.Department === formik.values[name][index].department)
      .map((el) => ({ jobGrade: el.jobGrade }));

    /*    const staffs = data.staffs.filter(
      (el) =>
        el.Department === formik.values[name][index].department &&
        el.jobGrade === formik.values[name][index].jobGrade
    ); */

    const staffs = data.staffs.filter((el) => {
      if (!formik.values[name][index].department) return true;
      if (
        !formik.values[name][index].department &&
        !formik.values[name][index].jobGrade
      )
        return true;
      if (
        formik.values[name][index].department &&
        !formik.values[name][index].jobGrade
      ) {
        return el.Department === formik.values[name][index].department;
      }
      if (
        formik.values[name][index].department &&
        formik.values[name][index].jobGrade
      ) {
        return (
          el.Department === formik.values[name][index].department &&
          el.jobGrade === formik.values[name][index].jobGrade
        );
      }
    });

    return {
      departments: [
        { label: "Any Department", value: "" },
        ...data.departments.map((el) => ({
          label: el.Department,
          value: el.Department,
        })),
      ],
      jobGrades: [
        { label: "Any Job Grade", value: "" },
        ...uniqBy(jobGrades, "jobGrade").map((el) => ({
          label: el.jobGrade,
          value: el.jobGrade,
        })),
      ],
      staffs: [
        { label: "Any Staff", value: "" },
        ...staffs.map((el) => ({
          label: el.Name,
          value: el.Staff_ID,
        })),
      ],
    };
  }, [data, name, index, formik.values]);

  return (
    <div className="col-md-6">
      {" "}
      <div className="d-flex justify-content-end mt-3">
        <Button
          title="Remove"
          variant="outline-danger"
          type="button"
          onClick={() => arrayHelpers.remove(index)}
        >
          ✖
        </Button>
      </div>
      <Form.Group className="mb-3">
        <Form.Label>Department :</Form.Label>
        <Select
          classNamePrefix={`form-select`}
          name={`${name}.${index}.department`}
          options={staffSelectOptions?.departments}
          value={staffSelectOptions?.departments.find(
            (el) => el.value === formik.values[name][index].department
          )}
          onChange={({ value }) =>
            formik.setFieldValue(`${name}.${index}.department`, value)
          }
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Job Grade :</Form.Label>
        <Select
          classNamePrefix={`form-select`}
          name={`${name}.${index}.jobGrade`}
          options={staffSelectOptions?.jobGrades}
          value={staffSelectOptions?.jobGrades.find(
            (el) => el.value === formik.values[name][index].jobGrade
          )}
          onChange={({ value }) =>
            formik.setFieldValue(`${name}.${index}.jobGrade`, value)
          }
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Recipient :</Form.Label>
        <Select
          classNamePrefix={`form-select`}
          name={`${name}.${index}.staff`}
          options={staffSelectOptions?.staffs}
          value={staffSelectOptions?.staffs.find(
            (el) => el.value === formik.values[name][index].staff
          )}
          onChange={({ value }) =>
            formik.setFieldValue(`${name}.${index}.staff`, value)
          }
          // isDisabled={isFetching}
        />
      </Form.Group>
      {hasLimit && (
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Limit Type:</Form.Label>
              <Select
                options={limitOptions}
                value={limitOptions?.find(
                  (el) => el.value === formik.values[name][index].limitType
                )}
                onChange={({ value }) =>
                  formik.setFieldValue(`${name}.${index}.limitType`, value)
                }
              />{" "}
            </Form.Group>
          </Col>{" "}
          <Col md={6}>
            {formik.values[name][index].limitType !== "any" && (
              <Form.Group className="mb-3">
                <Form.Label>Limit:</Form.Label>
                <CurrencyCustomInput
                  symbol=""
                  name={`${name}.${index}.limit`}
                  value={formik.values[name][index].limit}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                />
              </Form.Group>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
}

function MandatorySignatorySettings({ formik }) {
  const { user: authUser, backendUrl } = useAuth();

  const getDepartmentsForForm = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/users/departments-with-users`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    data = { departments: [], staffs: [], jobGrades: [] },
    isFetching,
  } = useQuery(
    [queryActions.GET_USERS_WITH_DEPARTMENTS],
    () => getDepartmentsForForm(),
    {
      enabled: true,
      keepPreviousData: true,
      cacheTime: cacheTimes.GET_USERS_WITH_DEPARTMENTS,
    }
  );

  return (
    <>
      <div>
        <hr />
        <h2 className="mb-3 h5">Signatory Settings</h2>

        <div>
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
              <h2 className="mb-3 h6">Fund Request Mandate</h2>

              <Form.Group className="mb-3 col-6">
                <Form.Label>
                  Number of Mandatory Signatories before Approve & Close
                </Form.Label>
                <NumberCustomInput
                  name="numberOfMandatoryApprovalsBeforeApproveAndClose"
                  value={
                    formik.values
                      .numberOfMandatoryApprovalsBeforeApproveAndClose
                  }
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                />
              </Form.Group>

              <FieldArray
                name="fundRequestMandatorySignatory"
                render={(arrayHelpers) => (
                  <>
                    <div className="row">
                      {formik.values.fundRequestMandatorySignatory.map(
                        (el, index) => (
                          <>
                            <StaffSelectorMultiple
                              data={data}
                              name="fundRequestMandatorySignatory"
                              index={index}
                              formik={formik}
                              arrayHelpers={arrayHelpers}
                              hasLimit={true}
                            />
                          </>
                        )
                      )}
                    </div>
                    <div className="d-flex justify-content-start px-4">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary text-nowrap"
                        onClick={() =>
                          arrayHelpers.push({
                            department: "",
                            jobGrade: "",
                            staff: "",
                          })
                        }
                      >
                        + Add
                      </button>
                    </div>
                  </>
                )}
              />
              <hr />
              <h2 className="mb-3 h6">Material Request</h2>
              <FieldArray
                name="materialRequestMandatorySignatory"
                render={(arrayHelpers) => (
                  <>
                    <div className="row">
                      {formik.values.materialRequestMandatorySignatory.map(
                        (el, index) => (
                          <>
                            <StaffSelectorMultiple
                              data={data}
                              name="materialRequestMandatorySignatory"
                              index={index}
                              formik={formik}
                              arrayHelpers={arrayHelpers}
                            />
                          </>
                        )
                      )}
                    </div>
                    <div className="d-flex justify-content-start px-4">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary text-nowrap"
                        onClick={() =>
                          arrayHelpers.push({
                            department: "",
                            jobGrade: "",
                            staff: "",
                          })
                        }
                      >
                        + Add
                      </button>
                    </div>
                  </>
                )}
              />

              <hr />
              <h2 className="mb-3 h6">Allowance</h2>
              <FieldArray
                name="allowanceMandatorySignatory"
                render={(arrayHelpers) => (
                  <>
                    <div className="row">
                      {formik.values.allowanceMandatorySignatory.map(
                        (el, index) => (
                          <>
                            <StaffSelectorMultiple
                              data={data}
                              name="allowanceMandatorySignatory"
                              index={index}
                              formik={formik}
                              arrayHelpers={arrayHelpers}
                              hasLimit={true}
                            />
                          </>
                        )
                      )}
                    </div>
                    <div className="d-flex justify-content-start px-4">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary text-nowrap"
                        onClick={() =>
                          arrayHelpers.push({
                            department: "",
                            jobGrade: "",
                            staff: "",
                          })
                        }
                      >
                        + Add
                      </button>
                    </div>
                  </>
                )}
              />
            </Form>
          </FormikProvider>
        </div>
      </div>
    </>
  );
}

export default function GeneralSettingsModal({
  showGeneralSettingsModal,
  setShowGeneralSettingsModal,
}) {
  const { backendUrl } = useAuth();
  const isAdmin = useIsAdmin();
  const isDocumentControl = useIsDocumentControl();
  //const generalSettings = useStoreState((state) => state.generalSettings);
  const setGeneralSettings = useStoreActions(
    (actions) => actions.setGeneralSettings
  );
  const [showJournalPopover, setShowJournalPopover] = useState(false);

  const addSettings = async (payload) => {
    let response = await fetch(`${backendUrl}/api/users/company-settings`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const addSettingsMutation = useMutation((payload) => addSettings(payload), {
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const formik = useFormik({
    initialValues: initialGeneralSettings,
    onSubmit: (values) => {
      //  Save  to  server
      addSettingsMutation.mutate(
        { settings: values },
        {
          onSuccess: ({ message, data }) => {
            setGeneralSettings(values);
            toast.success("Settings saved");
            setShowGeneralSettingsModal(false);
          },
        }
      );
    },
  });

  const setUp = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/users/company/settings`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    if (data.settings) {
      formik.setValues({ ...initialGeneralSettings, ...data.settings });
    }

    return data;
  };

  const { data, isFetching } = useQuery(
    ["GET_GLOBAL_SETTINGS"],
    () => setUp(),
    {
      enabled: true,
    }
  );
  const getUsersInSystem = async () => {
    let response = await fetch(`${backendUrl}/api/users`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const data = await response.json();
    data.allUsersInSystem = [
      {
        label: "None",
        value: "",
      },
      ...data.staff.map((el) => ({
        label: el.Name,
        value: el.Staff_ID,
      })),
    ];
    console.log(data);
    return data;
  };
  const {
    data: { allUsersInSystem } = { allUsersInSystem: [] },
    isFetching: isFetchingUsers,
  } = useQuery(["GET_USERS_IN_SYSTEM"], () => getUsersInSystem(), {});

  const handleSelectedJornalAccount = (account) => {
    formik.setFieldValue("drumAccountID", account?.AccountID);
    formik.setFieldValue("drumAccountDescription", account?.Description);
    setShowJournalPopover(false);
  };

  eventBus.useCustomEventListener("JOURNAL_ACCOUNT_CREATED", (account) => {
    handleSelectedJornalAccount(account);
  });

  return (
    <>
      <Modal
        size="lg"
        show={showGeneralSettingsModal}
        onHide={() => setShowGeneralSettingsModal(false)}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">
            <GearIcon /> Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormikProvider value={formik}>
            <Form
              noValidate
              onSubmit={formik.handleSubmit}
              autoComplete="off"
              style={
                isFetching || isFetchingUsers
                  ? { opacity: "0.5", pointerEvents: "none" }
                  : {}
              }
            >
              <h2 className="mb-3 h5">Personal</h2>
              <Form.Group>
                <Form.Check
                  inline
                  id="convertToTonsAndPcs"
                  label="Convert Quantity to Tons and pcs"
                  name="convertToTonsAndPcs"
                  type={"checkbox"}
                  checked={formik.values.convertToTonsAndPcs}
                  onChange={formik.handleChange}
                />
              </Form.Group>
              {isAdmin ? (
                <div className="row border-top my-4 pt-4">
                  <h2 className="mb-3 h5">All Users</h2>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="enableCreditLimit"
                        label="Enable Customers Credit Limit"
                        name="enableCreditLimit"
                        type={"checkbox"}
                        checked={formik.values.enableCreditLimit}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="enableCreditPaymentForWalkIn"
                        label="Allow Credit Invoice for Walk-In Customer"
                        name="enableCreditPaymentForWalkIn"
                        type={"checkbox"}
                        checked={formik.values.enableCreditPaymentForWalkIn}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Invoice workflow</Form.Label>

                      <div>
                        <Form.Check
                          inline
                          label="Proforma Invoice"
                          value="Proforma Invoice"
                          name="pendingInvoiceType"
                          type={"radio"}
                          id="Proforma Invoice"
                          checked={
                            formik.values.pendingInvoiceType ===
                            "Proforma Invoice"
                          }
                          onChange={formik.handleChange}
                        />
                        <Form.Check
                          inline
                          label="Quotation"
                          value="Quotation"
                          name="pendingInvoiceType"
                          type={"radio"}
                          id="Quotation"
                          checked={
                            formik.values.pendingInvoiceType === "Quotation"
                          }
                          onChange={formik.handleChange}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Item Type </Form.Label>

                      <div>
                        <Form.Check
                          inline
                          label="Inventory"
                          value="Inventory"
                          name="Item_Type"
                          type={"radio"}
                          id="Inventory"
                          checked={formik.values.Item_Type === "Inventory"}
                          onChange={formik.handleChange}
                        />
                        <Form.Check
                          inline
                          label="Service"
                          value="Service"
                          name="Item_Type"
                          type={"radio"}
                          id="Service"
                          checked={formik.values.Item_Type === "Service"}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </Form.Group>

                    {/* <Form.Group className="mb-3">
                  <Form.Label className="mb-1 text-nowrap">Currency</Form.Label>
                  <Select
                    classNamePrefix={"form-select"}
                    isSearchable={false}
                     value={didYouKnowOptions.find(
                      (el) => el.value === formik.values.How_Did_Know
                    )}
                    onChange={({ value }) =>
                      formik.setFieldValue("How_Did_Know", value)
                    }
                    options={didYouKnowOptions}
                  />
                  <Form.Control.Feedback type="invalid" />
                </Form.Group> */}

                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="hideDiscountColumn"
                        label="Hide Discount Column on Invoice"
                        name="hideDiscountColumn"
                        type={"checkbox"}
                        checked={formik.values.hideDiscountColumn}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="addSignatureOnInvoice"
                        label="Add Signature to Invoice"
                        name="addSignatureOnInvoice"
                        type={"checkbox"}
                        checked={formik.values.addSignatureOnInvoice}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="addItemDescriptionOnInvoice"
                        label="Show Item Description on Invoice"
                        name="addItemDescriptionOnInvoice"
                        type={"checkbox"}
                        checked={formik.values.addItemDescriptionOnInvoice}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Unit Price Column name on Invoice</Form.Label>
                      <Form.Control
                        id="unitPriceTextOnInvoice"
                        name="unitPriceTextOnInvoice"
                        value={formik.values.unitPriceTextOnInvoice}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="linkPaymentToInvoice"
                        label="Link Payment To Invoice"
                        name="linkPaymentToInvoice"
                        type={"checkbox"}
                        checked={formik.values.linkPaymentToInvoice}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="balanceInExpenseFromAccount"
                        label="Expense should be posted from account with balance"
                        name="balanceInExpenseFromAccount"
                        type={"checkbox"}
                        checked={formik.values.balanceInExpenseFromAccount}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="invoiceNoStockItem"
                        label="Invoice Item with not enough stock"
                        name="invoiceNoStockItem"
                        type={"checkbox"}
                        checked={formik.values.invoiceNoStockItem}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="poNumber"
                        label="Po Number"
                        name="poNumber"
                        type={"checkbox"}
                        checked={formik.values.poNumber}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="canBackDate"
                        label="Can Back Date"
                        name="canBackDate"
                        type={"checkbox"}
                        checked={formik.values.canBackDate}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>

                    <hr />
                    <h2 className="mb-3 h6">Drums Account</h2>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="enableDrumAccount"
                        label="Enable Drum Account"
                        name="enableDrumAccount"
                        type={"checkbox"}
                        checked={formik.values.enableDrumAccount}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Cost Per Drum</Form.Label>
                      <CurrencyCustomInput
                        id="costPerDrum"
                        name="costPerDrum"
                        value={formik.values.costPerDrum}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Drum Account</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          name="account"
                          placeholder="Select Drum Account"
                          value={`${formik.values?.drumAccountID ||
                            "..."} / ${formik.values?.drumAccountDescription ||
                            "..."}`}
                          onChange={() => {}}
                          readOnly
                        />

                        <Popover
                          isOpen={showJournalPopover}
                          onClickOutside={() => setShowJournalPopover(false)}
                          content={() => (
                            <ExpensesSelector
                              handleSelectedExpense={
                                handleSelectedJornalAccount
                              }
                              usage={"chart of accounts"}
                              onHide={() => setShowJournalPopover(false)}
                            />
                          )}
                          position="bottom"
                        >
                          <InputGroup.Text
                            onClick={() => setShowJournalPopover(true)}
                          >
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>
                      </InputGroup>
                    </Form.Group>

                    <hr />
                    <h2 className="mb-3 h6">Exchange Rate</h2>
                    <Form.Group className="mb-3">
                      <Form.Label>1 USD → NGN:</Form.Label>
                      <CurrencyCustomInput
                        id="dollarInBaseCurrency"
                        name="dollarInBaseCurrency"
                        value={formik.values.dollarInBaseCurrency}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value);
                        }}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-md-6">
                    {" "}
                    <h2 className="mb-3 h6">
                      Create/Update Item (Non Admin user){" "}
                    </h2>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="isPurchasePriceRequired"
                        label="Purchase Price is required"
                        name="isPurchasePriceRequired"
                        type={"checkbox"}
                        checked={formik.values.isPurchasePriceRequired}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="presetItemValues"
                        label="Preset Item UnitCost/Price in update item"
                        name="presetItemValues"
                        type={"checkbox"}
                        checked={formik.values.presetItemValues}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="presetUnitPrice"
                        label="Preset Unit Price in update item"
                        name="presetUnitPrice"
                        type={"checkbox"}
                        checked={formik.values.presetUnitPrice}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>{" "}
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="hasfreightVendor"
                        label="Has Freight Vendor"
                        name="hasfreightVendor"
                        type={"checkbox"}
                        checked={formik.values.hasfreightVendor}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <hr />
                    <h2 className="mb-3 h6">Post Invoice (Non Admin user)</h2>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="hideUnitCost"
                        label="Hide Unit Cost"
                        name="hideUnitCost"
                        type={"checkbox"}
                        checked={formik.values.hideUnitCost}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="hideUnitPrice"
                        label="Hide Unit Price"
                        name="hideUnitPrice"
                        type={"checkbox"}
                        checked={formik.values.hideUnitPrice}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="hideDiscount"
                        label="Hide Discount"
                        name="hideDiscount"
                        type={"checkbox"}
                        checked={formik.values.hideDiscount}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="presetFinalSellingPrice"
                        label="Preset Final Selling Price"
                        name="presetFinalSellingPrice"
                        type={"checkbox"}
                        checked={formik.values.presetFinalSellingPrice}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <hr />
                    <h2 className="mb-3 h6">Requires Admin Approval</h2>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="expenseRequiresAdminApproval"
                        label="Expense"
                        name="expenseRequiresAdminApproval"
                        type={"checkbox"}
                        checked={formik.values.expenseRequiresAdminApproval}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="editVendorPaymentRequiresAdminApproval"
                        label="Edit Vendor Payment"
                        name="editVendorPaymentRequiresAdminApproval"
                        type={"checkbox"}
                        checked={
                          formik.values.editVendorPaymentRequiresAdminApproval
                        }
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="deleteVendorPaymentRequiresAdminApproval"
                        label="Delete Vendor Payment"
                        name="deleteVendorPaymentRequiresAdminApproval"
                        type={"checkbox"}
                        checked={
                          formik.values.deleteVendorPaymentRequiresAdminApproval
                        }
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="editJournalRequiresAdminApproval"
                        label="Edit Journal Entry"
                        name="editJournalRequiresAdminApproval"
                        type={"checkbox"}
                        checked={formik.values.editJournalRequiresAdminApproval}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Fund Request Reimbursement Duration
                      </Form.Label>

                      <Row>
                        <Col>
                          <NumberCustomInput
                            placeholder="0"
                            name="retirementDurationNumber"
                            value={formik.values.retirementDurationNumber}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(name, value, true);
                            }}
                            // isInvalid={formik.touched.Quantity && !!formik.errors.Quantity}
                          />
                        </Col>
                        <Col>
                          <Select
                            classNamePrefix={"form-select"}
                            placeholder={""}
                            value={durationOptions.find(
                              (el) =>
                                el.value ===
                                formik.values.retirementDurationValue
                            )}
                            options={durationOptions}
                            onChange={({ value }) =>
                              formik.setFieldValue(
                                "retirementDurationValue",
                                value
                              )
                            }
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        label="Enable General Discount"
                        id="enableGeneralDiscount"
                        name="enableGeneralDiscount"
                        type={"checkbox"}
                        checked={formik.values.enableGeneralDiscount}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    {formik.values.enableGeneralDiscount && (
                      <Row>
                        <Form.Group className="col-md-5 mb-3 pb-2">
                          <Form.Label className="mb-1">
                            General Discount Type
                          </Form.Label>
                          <Select
                            classNamePrefix={"form-select"}
                            options={amountTypes}
                            value={amountTypes.find(
                              (el) =>
                                el.value === formik.values.generalDiscountType
                            )}
                            onChange={(selected) =>
                              formik.setFieldValue(
                                "generalDiscountType",
                                selected.value
                              )
                            }
                          />
                        </Form.Group>

                        {formik.values.generalDiscountType === "Percentage" ? (
                          <Form.Group className="col-md-6 mb-3 pb-2">
                            <Form.Label className="mb-1">
                              General Discount (%)
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="generalDiscountPercentage"
                              value={formik.values.generalDiscountPercentage}
                              onChange={formik.handleChange}
                            />
                          </Form.Group>
                        ) : (
                          <Form.Group className="col-md-7 mb-3 pb-2">
                            <Form.Label className="mb-1">
                              General Discount Amount
                            </Form.Label>
                            <CurrencyCustomInput
                              name="generalDiscount"
                              placeholder="0.00"
                              value={formik.values.generalDiscount}
                              onValueChange={(value, name) => {
                                formik.setFieldValue(name, value);
                              }}
                            />
                          </Form.Group>
                        )}
                      </Row>
                    )}
                    <Form.Group className="mb-3">
                      <Form.Label className="d-flex justify-content-between">
                        <span>TAX</span> <span> Percentage(%)</span>
                      </Form.Label>

                      <FieldArray
                        name="taxOptions"
                        render={(arrayHelpers) => (
                          <>
                            <div className="row">
                              {formik.values.taxOptions.map((el, index) => (
                                <div className="d-flex gap-3 mb-2">
                                  <Form.Control
                                    name={`taxOptions[${index}].name`}
                                    placeholder="Name"
                                    value={formik.values.taxOptions[index].name}
                                    onChange={formik.handleChange}
                                  />
                                  <Form.Control
                                    type="number"
                                    name={`taxOptions[${index}].percentage`}
                                    placeholder="Percentage"
                                    value={
                                      formik.values.taxOptions[index].percentage
                                    }
                                    onChange={formik.handleChange}
                                  />
                                  <Button
                                    title="Remove"
                                    variant=""
                                    type="button"
                                    size="xs"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    ✖
                                  </Button>
                                </div>
                              ))}
                            </div>
                            <div className="d-flex justify-content-start px-4">
                              <button
                                type="button"
                                className="btn btn-sm btn-primary text-nowrap"
                                onClick={() =>
                                  arrayHelpers.push({
                                    department: "",
                                    jobGrade: "",
                                    staff: "",
                                  })
                                }
                              >
                                + Add
                              </button>
                            </div>
                          </>
                        )}
                      />
                    </Form.Group>
                  </div>

                  {/*  <Form.Group className="mb-3">
                    <Form.Label>Mandatory Signatory (Fund Request):</Form.Label>

                    <Select
                      classNamePrefix={"form-select"}
                      options={allUsersInSystem}
                      value={allUsersInSystem.find(
                        (el) =>
                          el.value === formik.values.requiredSignatoryStaff_ID
                      )}
                      onChange={(selected) =>
                        formik.setFieldValue(
                          "requiredSignatoryStaff_ID",
                          selected.value
                        )
                      }
                    />
                  </Form.Group> */}

                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      id="processStaffSalaryInHr"
                      label="Process Staff Salary in HR"
                      name="processStaffSalaryInHr"
                      type={"checkbox"}
                      checked={formik.values.processStaffSalaryInHr}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <div>
                    <Form.Group className="mb-3 pb-2">
                      <Form.Label>Item Expiry Filter Duration</Form.Label>

                      <Row>
                        <Col>
                          <NumberCustomInput
                            placeholder="0"
                            name="expiryFilterDurationNumber"
                            value={formik.values.expiryFilterDurationNumber}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(name, value, true);
                            }}
                            // isInvalid={formik.touched.Quantity && !!formik.errors.Quantity}
                          />
                        </Col>
                        <Col>
                          <Select
                            classNamePrefix={"form-select"}
                            placeholder={""}
                            value={durationOptions.find(
                              (el) =>
                                el.value ===
                                formik.values.expiryFilterDurationValue
                            )}
                            options={durationOptions}
                            onChange={({ value }) =>
                              formik.setFieldValue(
                                "expiryFilterDurationValue",
                                value
                              )
                            }
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                </div>
              ) : null}

              {isAdmin || isDocumentControl ? (
                <MandatorySignatorySettings formik={formik} />
              ) : null}

              <div>
                <hr />
                <h2 className="mb-3 h5">Quality Assurance </h2>

                <div className="row">
                  <div className="col-6">
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="WarehouseRecieveAssurance"
                        label="Warehouse Recieve Assurance"
                        name="WarehouseRecieveAssurance"
                        type={"checkbox"}
                        checked={formik.values.WarehouseRecieveAssurance}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="RM_FPApproval"
                        label="RM & FP Approval"
                        name="RM_FPApproval"
                        type={"checkbox"}
                        checked={formik.values.RM_FPApproval}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="CofAApproval"
                        label="C of A Approval"
                        name="CofAApproval"
                        type={"checkbox"}
                        checked={formik.values.CofAApproval}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </Form>{" "}
          </FormikProvider>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => formik.submitForm()}
            variant="primary"
            disabled={addSettingsMutation.isLoading}
            className="px-4"
          >
            {addSettingsMutation.isLoading ? "Please wait..." : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
