import { useFormik } from "formik";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal, Form, Nav, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import "../assets/scss/RolesPriviledgesModal.scss";
import * as yup from "yup";
import EyeOffOutline from "mdi-react/EyeOffOutlineIcon";
import EyeOutline from "mdi-react/EyeOutlineIcon";

import useDebounce, {
  useBackendUrl,
  useEffectOnce,
  useIsAdmin,
} from "../utils/hooks";
import { useFetchUsers } from "../hooks/useFetchUsers";
import Avatar from "./utils/Avatar";
import { useAuth } from "../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import { GearIcon } from "./Icons";
import { useMutation, useQuery } from "react-query";
import { initialGeneralSettings } from "../utils/helpers";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import ExpensesSelector from "./utils/ExpensesSelector";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Popover } from "react-tiny-popover";
import eventBus from "../utils/EventBus";
import roles from "./roles.json"

export default function RolesPriviledgesModal({
  showRolesPriviledgesModal,
  setShowRolesPriviledgesModal,
}) {
  const { backendUrl } = useAuth();
  const isAdmin = useIsAdmin();
  //const generalSettings = useStoreState((state) => state.generalSettings);
  const setGeneralSettings = useStoreActions(
    (actions) => actions.setGeneralSettings
  );
  const [showJournalPopover, setShowJournalPopover] = useState(false);
  const [userRoles, setUserRoles] = useState(roles.roles)
  const [preveledges, setPreveledges] = useState([
    // {
    //   name: '', checked: false, parent: ''
    // }, {


    // }

  ])


  const isChecked = (item) => {
    console.log(item)

    const found = preveledges.find(el => el.name === item.name)
    console.log('found')

    console.log(found)

    if (!found) {
      return false
    }


    return !found.checked

  }

  const handleChange = (checked, role) => {

    console.log(checked)

    // const found = preveledges.find(el => el.name === item.name)
    console.log(preveledges)
    console.log(role.priviledges)

    if (role.priviledges !== undefined) {
      role.priviledges.map(priviledge => {
        setPreveledges(preveledges => [
          ...preveledges.filter(el => el.name !== priviledge.name), {
            name: priviledge.name, checked: checked, parent: role.name
          }
        ])

        // console.log('Priviledges:')
        // console.log(priviledge)
        // console.log(priviledge.actions)

        if (priviledge.actions !== undefined) {
          priviledge.actions.map(action => {
            setPreveledges(preveledges => [
              ...preveledges.filter(el => el.name !== action.name), {
                name: action.name, checked: checked, parent: role.name
              }
            ])
          })
        }
      })
    }

    if (role.actions !== undefined) {
      role.actions.map(action => {
        setPreveledges(preveledges => [
          ...preveledges.filter(el => el.name !== action.name), {
            name: action.name, checked: checked, parent: role.name
          }
        ])
      })
    }

    setPreveledges(preveledges => [
      ...preveledges.filter(el => el.name !== role.name), {
        name: role.name, checked: checked, parent: ''
      }
    ])

    console.log(preveledges)
  }


  const addSettings = async (payload) => {
    let response = await fetch(`${backendUrl}/api/users/company-settings`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const addSettingsMutation = useMutation((payload) => addSettings(payload), {
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const formik = useFormik({
    initialValues: initialGeneralSettings,
    onSubmit: (values) => {
      //  Save  to  server
      addSettingsMutation.mutate(
        { settings: values },
        {
          onSuccess: ({ message, data }) => {
            setGeneralSettings(values);
            toast.success("Settings saved");
            setShowRolesPriviledgesModal(false);
          },
        }
      );
    },
  });

  const setUp = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/users/company/settings`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    if (data.settings) {
      formik.setValues({ ...initialGeneralSettings, ...data.settings });
    }

    return data;
  };

  const { data, isFetching } = useQuery(
    ["GET_GLOBAL_SETTINGS"],
    () => setUp(),
    {
      enabled: true,
    }
  );
  const getUsersInSystem = async () => {
    let response = await fetch(`${backendUrl}/api/users`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const data = await response.json();
    data.allUsersInSystem = [
      {
        label: "Select User",
        value: "",
      },
      ...data.staff.map((el) => ({
        label: el.Name,
        value: el.Staff_ID,
      })),
    ];
    console.log(data);
    return data;
  };
  const {
    data: { allUsersInSystem } = { allUsersInSystem: [] },
    isFetching: isFetchingUsers,
  } = useQuery(["GET_USERS_IN_SYSTEM"], () => getUsersInSystem(), {});

  console.log(data)

  const handleSelectedJornalAccount = (account) => {
    formik.setFieldValue("drumAccountID", account?.AccountID);
    formik.setFieldValue("drumAccountDescription", account?.Description);
    setShowJournalPopover(false);
  };

  eventBus.useCustomEventListener("JOURNAL_ACCOUNT_CREATED", (account) => {
    handleSelectedJornalAccount(account);
  });

  const animatedComponents = makeAnimated()
  const users = useFetchUsers()

  return (
    <>
      <Modal
        size="xl"
        show={showRolesPriviledgesModal}
        onHide={() => setShowRolesPriviledgesModal(false)}
        enforceFocus={false}
        className={'roles-priviledges'}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">
            <GearIcon /> Roles & Priviledge Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            style={
              isFetching || isFetchingUsers
                ? { opacity: "0.5", pointerEvents: "none" }
                : {}
            }
          >
            <h2 className="mb-3 mt-2 h5">Users</h2>
            <Form.Group className={'col-sm-12 col-md-4'}>
              <Select
                isMulti
                components={animatedComponents}
                options={users}
                value={users.find(
                  (el) =>
                    el.value === formik.values.requiredSignatoryStaff_ID
                )}
                onChange={(selected) =>
                  formik.setFieldValue(
                    "requiredSignatoryStaff_ID",
                    selected.value
                  )
                }
              />
            </Form.Group>

            {isAdmin ? (
              <div className="roles border-top my-4 pt-4">
                {userRoles.map((role, index) => (
                  <div className={'role-item'}>
                    <h6 key={index}>
                      <Form.Check inline type={'checkbox'} checked={isChecked(role)} onChange={(e) => handleChange(isChecked(role), role)} />&nbsp;
                      {role.name}
                    </h6>
                    <div className="priviledges">
                      {role.priviledges.map((priviledge, p) => (
                        <>
                          <p><Form.Check inline type={'checkbox'} checked={isChecked(priviledge)} onChange={(e) => handleChange(isChecked(priviledge), priviledge)} />&nbsp;{priviledge.name}</p>
                          <ul className="actions">
                            {priviledge.actions?.map((action, a) => (
                              <li key={a}><Form.Check inline type={'checkbox'} checked={isChecked(action)} onChange={(e) => handleChange(isChecked(action), action)} />&nbsp;{action.name}</li>
                            ))}
                          </ul>
                        </>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => formik.submitForm()}
            variant="primary"
            disabled={addSettingsMutation.isLoading}
            className="px-4"
          >
            {addSettingsMutation.isLoading ? "Please wait..." : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
