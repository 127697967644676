import { Modal, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { services } from "../config";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useMemo, useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import Select from "react-select";
import { useEffect } from "react";
import { useBackendUrl } from "../utils/hooks";
import CachedIcon from "mdi-react/CachedIcon";
import ConfirmDialog from "./ConfirmDialogue";

function SelectBank({ proceed, show, hasChequeNumber, selectedBank }) {
  const backendUrl = useBackendUrl();
  const [isLoading, setIsLoading] = useState(false);
  const [banks, setBanks] = useState([]);

  const initialValues = {
    bank: selectedBank,
    ...(hasChequeNumber ? { chequeNumber: "" } : {}),
  };
  const schema = yup.object().shape({
    bank: yup.string().required(),
    /*  ...(hasChequeNumber
      ? { chequeNumber: yup.string().required("required") }
      : {}), */
  });

  const getBanks = async () => {
    try {
      setIsLoading(true);
      let response = await fetch(`${backendUrl}/api/users/banks`, {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        response = await response.json();
        toast.error(response.message);
      } else {
        const {
          data: { banks = [] },
        } = await response.json();
        setBanks(
          banks.map((el) => ({
            ...el,
            value: el.BankName,
            label: `${el.BankName} ${
              el?.currency ? `(${el?.currency})` : `(NGN)`
            }`,
          }))
        );
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to get banks");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBanks();
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      proceed({ ...values, currency: bankCurrency });
    },
  });

  const canClose = async () => {
    if (!isLoading) {
      if (!formik.values.bank) {
        formik.setFieldTouched("bank", true);
        formik.setFieldError("bank", "Please select a bank");
        if (
          await ConfirmDialog({
            title: "Are you sure ?",
            description: "Close without selecting a bank",
          })
        ) {
          proceed({ ...formik.values, currency: bankCurrency });
        }
      } else {
        proceed({ ...formik.values, currency: bankCurrency });
      }
    }
  };

  const bankCurrency = useMemo(() => {
    const selectedBank = banks.find((el) => el.value === formik.values.bank);
    return selectedBank?.currency ? selectedBank?.currency : "NGN";
  }, [formik, formik.values.bank, selectedBank]);

  return (
    <Modal
      show={show}
      onHide={() => canClose()}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6">
            Select Bank
            <Button
              onClick={() => getBanks()}
              variant="white"
              className="text-primary"
            >
              <CachedIcon />
            </Button>{" "}
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Bank</Form.Label>
            <Select
              classNamePrefix="form-select"
              placeholder="Select"
              disabled={isLoading}
              isSearchable={false}
              options={banks}
              value={banks.find((el) => el.value === formik.values.bank)}
              onChange={({ value }) => formik.setFieldValue("bank", value)}
              onBlur={() => formik.setFieldTouched("bank", true)}
              className={
                formik.touched.bank && !!formik.errors.bank ? "is-invalid" : ""
              }
            />
            {formik.touched.bank && formik.errors.bank ? (
              <span className="custom-invalid-feedback">
                {formik.errors.bank}
              </span>
            ) : null}
          </Form.Group>

          {hasChequeNumber && (
            <Form.Group className="mb-3">
              <Form.Label>Cheque No</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Cheque Number"
                name="chequeNumber"
                value={formik.values.chequeNumber}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.chequeNumber && !!formik.errors.chequeNumber
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.chequeNumber}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          <Button
            disabled={isLoading}
            variant="primary"
            className="w-100 p-2"
            type="submit"
          >
            {isLoading ? "Please wait…" : "Continue"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default function SelectBankDialog(props) {
  return createConfirmation(confirmable(SelectBank))({ ...props });
}
