import { Dropdown, Table, Form, Button, InputGroup } from "react-bootstrap";
import { CSVLink } from "react-csv";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  RecieptIcon,
  PageHeaderListIcon,
  BookIcon,
  ExcelIcon,
  PDFIcon,
  NoSelectedItemIcon,
  DeleteIcon,
  LinkVariantIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useIsAdmin,
  useIsCashier,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { appSettings, services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useQuery, useMutation } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  copyText,
  customerFullName,
  defaultSelectValue,
  formatDate,
  formatNumberSystem,
  paginationOptions,
  reactSelectTheme,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { useAuth } from "../../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import CustomerSelector from "../utils/CustomerSelector";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { lowerCase } from "lodash";
import { isEmpty } from "lodash";
import NoTableItem from "../utils/NoTableItem";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import TableComponent from "../TableComponent";
import TransactionsTable from "../utils/TransactionsTable";
import { currencies } from "../../utils/currencies";
import { read, utils } from "xlsx";
import * as yup from "yup";
import ConfirmDialog from "../ConfirmDialogue";
import { toast } from "react-toastify";

const currenciesOptions = [
  {
    label: "All Currencies",
    value: "",
  },
  ...currencies.map((el) => ({
    ...el,
    label: `${el.name} (${el.symbol})`,
    value: el.cc,
  })),
];

export default function Invoices() {
  useScrollTop();
  const navigate = useNavigate();
  const initialFilterParams = {
    customerName: "",
    customerId: "",
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    enableDateRange: true,
    DocType: "Invoice",
  };

  const { backendUrl } = useAuth();
  const isCashier = useIsCashier();
  const isAdmin = useIsAdmin();
  const [activeRow, setActiveRow] = useState(null);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [excelData, setExcelData] = useState([]);
  const [showTransactionPopover, setShowTransactionPopover] = useState(false);
  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchCustomerLedgers = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/customer-ledger?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.invoiceCat = data.invoiceCat.map((el) => ({
      value: el,
      label: el,
    }));
    return data;
  };

  const {
    data = {
      count: 0,
      customerLedgers: [],
    },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [reportActions.SALE_BY_INVOICE, queryParams],
    () => fetchCustomerLedgers(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/reports/customer-ledger?${queryString.stringify(
        rest
      )}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;
    exData = exData.data.customerLedgers.map((row) => ({
      ...row,
      Post_Time: format(new Date(row.Post_Time), "dd-MMM-yyyy hh:mm:ss a"),
    }));

    exData = exData.map((d) => [
      d.customer.LastName,
      d.Post_Time,
      d.DocType,
      d.Trans_ID,
      d.PaymentType,
      d.Debit,
      d.Credit,
      d.Username,
      d.InvoiceCat,
    ]);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company],
      ["Customer Invoices and Payments Report"],
      [date],
      [""],
      [
        "Customer Name",
        "Trans Date",
        "Doc Type",
        "Trans ID",
        "Payment Type",
        "Debit Amount",
        "Credit Amount",
        "Username",
        "InvoiceCat",
      ],
      ...exData,
      [""],
      ["Summary"],
      [
        "Total Invoice Amount",
        currency(data?.totalInvoiceAmt, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Payment Amount",
        currency(data?.totalPaymentAmt, {
          symbol: "",
        }).format(),
      ],
      [
        "Balance (LCY)",
        currency(data?.balance, {
          symbol: "",
        }).format(),
      ],
      [
        "Reversed Invoice Amount",
        currency(data?.reversedInvoiceAmount, {
          symbol: "",
        }).format(),
      ],
      [
        "Reversed Payment Amount",
        currency(data?.reversedPaymentAmount, {
          symbol: "",
        }).format(),
      ],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedCustomer = (customer) => {
    setFilterParams({
      ...filterParams,
      customerId: customer.Cust_ID,
      customerName: customerFullName(customer),
    });
    setShowItemsPopover(false);
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th /> <th>#</th>
          <th>Customer Name</th>
          <th>Trans Date</th>
          <th>Doc Type</th>
          <th>Trans ID</th>
          {/* <th>Payment Type</th> */}
          <th>Amount</th>
          {/*  <th>Credit Amount</th> */}
          <th>Username</th>
          {/* <th>InvoiceCat</th> */}
        </tr>
      </thead>
    );
  };

  const tableData = (el, index) => {
    return (
      <>
        <td>
          {["invoice", "official receipt"].includes(lowerCase(el.DocType)) && (
            <Dropdown>
              <Dropdown.Toggle
                variant=""
                className="bg-white border-0"
                bsPrefix="print more"
              >
                <DotsVerticalIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu
                popperConfig={{
                  strategy: "fixed",
                }}
                renderOnMount
                className="dropdown-with-icons"
              >
                {lowerCase(el.DocType) === "invoice" && (
                  <>
                    <Dropdown.Item as="div">
                      <a
                        href={`${backendUrl}/api/invoice/pdf/invoice/${
                          el.Trans_ID
                        }`}
                        target="blank"
                      >
                        <BookIcon />
                        Open Invoice - A4
                      </a>
                    </Dropdown.Item>
                    <Dropdown.Item as="div">
                      <a
                        href={`${backendUrl}/api/invoice/pdf/invoice/${
                          el.Trans_ID
                        }?thermalPrinter=true`}
                        target="blank"
                      >
                        <BookIcon />
                        Open Invoice - Thermal Printer
                      </a>
                    </Dropdown.Item>
                    <Dropdown.Item as="button">
                      <a
                        href={`${backendUrl}/api/invoice/pdf/waybill/${
                          el.Trans_ID
                        }`}
                        target="blank"
                      >
                        <PageHeaderListIcon />
                        Open Waybill
                      </a>
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={() =>
                        copyText(
                          `${
                            services.frontendUrl
                          }/sales-and-invoicing/delete-invoice?TransactionID=${
                            el.Trans_ID
                          }`
                        )
                      }
                    >
                      <LinkVariantIcon /> Copy Delete Invoice Link
                    </Dropdown.Item>
                    {isAdmin || (appSettings.isGigc && isCashier) ? (
                      <Dropdown.Item as="div">
                        <Link
                          to={`/sales-and-invoicing/delete-invoice?TransactionID=${
                            el.Trans_ID
                          }`}

                          // target="blank"
                        >
                          <DeleteIcon /> Delete Invoice
                        </Link>
                      </Dropdown.Item>
                    ) : null}
                  </>
                )}
                {lowerCase(el.DocType) === "official receipt" && (
                  <>
                    <Dropdown.Item as="div">
                      <a
                        href={`${backendUrl}/api/invoice/pdf/receipt/${
                          el.Trans_ID
                        }`}
                        target="blank"
                      >
                        <RecieptIcon />
                        Open Reciept - A4
                      </a>
                    </Dropdown.Item>

                    <Dropdown.Item as="div">
                      <a
                        href={`${backendUrl}/api/invoice/pdf/receipt/${
                          el.Trans_ID
                        }?thermalPrinter=true`}
                        target="blank"
                      >
                        <RecieptIcon />
                        Open Reciept - Thermal Printer
                      </a>
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={() =>
                        copyText(
                          `${
                            services.frontendUrl
                          }/sales-and-invoicing/delete-payment?TransactionID=${
                            el.Trans_ID
                          }&PaymentType=${el.PaymentType}&Balance=${el.Balance}`
                        )
                      }
                    >
                      <LinkVariantIcon /> Copy Delete Payment Link
                    </Dropdown.Item>
                    {isAdmin || (appSettings.isGigc && isCashier) ? (
                      <Dropdown.Item onClick={() => navigate()} as="div">
                        <Link
                          to={`/sales-and-invoicing/delete-payment?TransactionID=${
                            el.Trans_ID
                          }&PaymentType=${el.PaymentType}&Balance=${
                            el.Balance
                          }`}
                        >
                          <DeleteIcon /> Delete Payment
                        </Link>
                      </Dropdown.Item>
                    ) : null}
                  </>
                )}
              </Dropdown.Menu>{" "}
            </Dropdown>
          )}
        </td>
        <td className="text-nowrap">
          {formatNumberSystem(el?.numberPrefix, el?.currentNumber)}
        </td>
        <td>
          {el?.customer?.LastName === "Walk-In" && el?.transaction
            ? `${el?.transaction?.ShipTo} (Walk-In)`
            : customerFullName(el.customer)}
        </td>
        <td>{el.Post_Time ? formatDate(el.Post_Time, "dd MMM, yyyy") : ""}</td>
        <td>{el.DocType}</td>
        <td>{el.Trans_ID}</td>
        {/*  <td>{el.PaymentType}</td> */}
        <td>
          {el.currency && el.currency !== "NGN"
            ? currency(el.Debit, {
                symbol: "$",
              })
                .divide(el.conversionAmount)
                .format()
            : currency(el.Debit, {
                symbol: "₦",
              }).format()}
        </td>
        {/*  <td>
          {currency(el.Credit, {
            symbol: "",
          }).format()}
        </td> */}
        <td>{el.Username}</td>
        {/*  <td>{el.InvoiceCat}</td> */}
      </>
    );
  };

  const manageTransaction = (event, el) => {
    if (event.detail === 2 && el.Trans_ID) {
      navigate(`/inventory-for-sales/manage-transaction`, {
        state: {
          TransactionID: el.Trans_ID,
        },
      });
    }
  };

  const handleSelectedTransaction = (transaction) => {
    setFilterParams({
      ...filterParams,
      TransactionID: transaction.TransactionID,
    });
    setShowTransactionPopover(false);
  };

  const addMultipleInvoices = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/invoice/create-multiple-invoice`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const addMultipleInvoicesMutation = useMutation(
    (payload) => addMultipleInvoices(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        refetch();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  function handleFile({ eventData: e }) {
    /*  toast.info("Service not Available");
    return; */
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async function(e) {
        const workbook = read(e.target.result, {
          cellDates: true,
          dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
        });
        const rows = utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          {
            defval: "",
            dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
          }
        );
        console.log("excel", rows);
        if (
          !(await ConfirmDialog({
            title: "Are you sure?",
            description: "You want to import",
          }))
        ) {
          return;
        }
        // TODO - validate excel
        let schema = yup.object().shape({
          items: yup.array().of(
            yup.object({
              currency: yup.string().required(),
              conversionAmount: yup.number().required(),
              Date_Log: yup.string().default(new Date().toISOString()),
              Item_Name: yup.string().required(),
              Amount: yup.number().required(),
              Cust_ID: yup.string().required(),
              jobNumber: yup.string().required(),
              jobTitle: yup.string().required(),
            })
          ),
        });
        try {
          await schema.validate({ items: rows });

          addMultipleInvoicesMutation.mutate({
            items: rows.map((el) => ({
              ...el,
            })),
          });

          //   resolve(validData);
        } catch (err) {
          console.log(err);
          toast.error(err.name);
          toast.error(JSON.stringify(err.errors));
        }
      };
      reader.readAsArrayBuffer(file);
    }
    e.target.value = "";
  }

  return (
    <main className="cash-book">
      <div className="content">
        <div className="content-main">
          <div className={`filter-area ${showFilter ? "open" : "close"}`}>
            <div className="filter-header">
              <h2>
                <FilterTwoIcon /> Filters
              </h2>

              <Button
                variant="white"
                onClick={() => setShowFilter(!showFilter)}
              >
                <FilterCollapseIcon />
              </Button>
            </div>
            <div className="filter-body">
              <Form>
                <div className="body">
                  {" "}
                  <Form.Group className="mb-2-5">
                    <Form.Label>Transaction ID</Form.Label>{" "}
                    <InputGroup>
                      <Form.Control
                        name="TransactionID"
                        value={filterParams?.TransactionID}
                        placeholder="Enter Transaction ID"
                        onChange={(e) => handleFilterParamsChange(e)}
                      />{" "}
                      <Popover
                        isOpen={showTransactionPopover}
                        onClickOutside={() => setShowTransactionPopover(false)}
                        content={() => (
                          <TransactionsTable
                            handleSelectedTransaction={
                              handleSelectedTransaction
                            }
                          />
                        )}
                        position="bottom"
                      >
                        <InputGroup.Text
                          onClick={() =>
                            setShowTransactionPopover(!showTransactionPopover)
                          }
                        >
                          <MagnifyIcon />
                        </InputGroup.Text>
                      </Popover>{" "}
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-2-5">
                    <Form.Label>Customer ID</Form.Label>
                    <InputGroup>
                      <Form.Control
                        name="customerId"
                        placeholder="Enter Customer ID"
                        value={filterParams?.customerId}
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                      <Popover
                        isOpen={showItemsPopover}
                        onClickOutside={() => setShowItemsPopover(false)}
                        content={() => (
                          <CustomerSelector
                            handleSelectedCustomer={handleSelectedCustomer}
                            setShowCustomerSelector={setShowItemsPopover}
                          />
                        )}
                        position="bottom"
                      >
                        <InputGroup.Text
                          onClick={() => setShowItemsPopover(!showItemsPopover)}
                        >
                          <MagnifyIcon />
                        </InputGroup.Text>
                      </Popover>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-2-5">
                    <Form.Label>Customer Name</Form.Label>
                    <Form.Control
                      name="customerName"
                      value={filterParams?.customerName}
                      placeholder="Enter Customer Name"
                      onChange={(e) => handleFilterParamsChange(e)}
                    />
                  </Form.Group>
                  {/*  <Form.Group className="mb-2-5">
                    <Form.Label>Invoice Cat</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      placeholder="All"
                      theme={reactSelectTheme}
                      isSearchable={false}
                      key={data?.invoiceCat}
                      defaultValue={defaultSelectValue(
                        queryParams?.invoiceCat,
                        data?.invoiceCat,
                        {
                          value: "",
                          label: "All",
                        }
                      )}
                      options={data?.invoiceCat || []}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          invoiceCat: selected.value,
                        });
                      }}
                    />
                  </Form.Group> */}
                  <hr className="mt-3 mb-4" />
                  <div className="mb-2-5">
                    <Form.Check
                      inline
                      label="Enable Date Range"
                      name="enableDateRange"
                      type={"checkbox"}
                      id={`enableDateRange`}
                      checked={filterParams?.enableDateRange}
                      onChange={(e) => handleFilterParamsChange(e)}
                    />
                  </div>
                  <Form.Group className="">
                    <Form.Label>Date Range</Form.Label>
                    <div className="position-relative">
                      <RsDateRangePicker
                        placement="topStart"
                        value={
                          filterParams.startDate && filterParams.endDate
                            ? [
                                parse(
                                  filterParams.startDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                                parse(
                                  filterParams.endDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                              ]
                            : []
                        }
                        onClean={() => clearDateRange()}
                        onOk={(date) => filterByDateRange(date)}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="buttons rounded">
                  <Button onClick={() => reset()} variant="white">
                    Reset
                  </Button>
                  <Button onClick={() => search()} variant="primary">
                    Search
                  </Button>
                </div>
              </Form>
            </div>
          </div>
          <div className="content-body">
            <header>
              <h1>
                {!showFilter && (
                  <button
                    onClick={() => setShowFilter(!showFilter)}
                    className="btn filter"
                  >
                    <FilterTwoIcon />
                  </button>
                )}
                Invoices
                <button
                  title="Refresh"
                  onClick={() => refetch()}
                  className="btn text-primary"
                >
                  <CachedIcon />
                </button>
              </h1>
              <div
                className="actions"
                style={{
                  zIndex: "10",
                }}
              >
                <Form.Group>
                  <Select
                    classNamePrefix="form-select"
                    placeholder="Select Currency"
                    isSearchable={false}
                    options={currenciesOptions}
                    value={currenciesOptions.find(
                      (el) => el.value === filterParams.currency
                    )}
                    onChange={({ value }) => {
                      setFilterParams({
                        ...filterParams,
                        currency: value,
                      });
                    }}
                  />
                </Form.Group>

                <CSVLink
                  className="btn print d-none"
                  filename={`Invoices(${format(
                    new Date(),
                    "dd-MMM-yyyy hh:mm:ss a"
                  )}).csv`}
                  data={excelData}
                  ref={CSVLinkRef}
                />

                <Dropdown>
                  <Dropdown.Toggle
                    variant=""
                    className="btn print"
                    disabled={isfetchingExcel}
                    bsPrefix=""
                  >
                    Export
                    <ExportIcon color="#008000" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    popperConfig={{
                      strategy: "fixed",
                    }}
                    renderOnMount
                    className="text-center"
                  >
                    <Dropdown.Item
                      as="div"
                      onClick={onDownloadExcelData}
                      className="p-cursor"
                    >
                      Excel <ExcelIcon color="#008000" />
                    </Dropdown.Item>
                    <Dropdown.Item as="div">
                      <a
                        href={`${backendUrl}/api/reports/pdf/sale-by-invoice?${queryString.stringify(
                          rest
                        )}`}
                        target="blank"
                      >
                        PDF <PDFIcon color="#ff0000" />
                      </a>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Button
                  as="label"
                  className="p-cursor"
                  title="Import"
                  variant="light-blue"
                >
                  <input
                    type="file"
                    className="d-none"
                    onChange={(e) => handleFile({ eventData: e })}
                    accept=".xlsx"
                  />
                  <ExcelIcon color="#008000" /> Import Invoice
                </Button>

                <button
                  onClick={() => setBtnSummaryActive(true)}
                  className="btn summaryBtn "
                >
                  Show Summary
                </button>
              </div>
            </header>

            <div className="px-md-4">
              <TableComponent
                responsive
                borderless
                striped
                tableHeadsFunction={tableHead}
                mainDataArray={data.customerLedgers}
                tableDataRowFunction={tableData}
                className="product-table text-nowrap"
                tableRowClick={(e, el) => manageTransaction(e, el)}
              />

              {!isFetching && isSuccess && isEmpty(data?.customerLedgers) ? (
                <NoTableItem queryParams={queryParams} />
              ) : null}
            </div>

            <div className="d-flex justify-content-between px-3 align-items-center pagination">
              <div className="pagination_left">
                <p className="m-0 p-0">Show</p>
                <select
                  value={queryParams.limit}
                  name="limit"
                  className="form-select "
                  onChange={(e) => handleSearchQueryChange(e)}
                >
                  <option value="10">10 rows</option>
                  <option value="20">20 rows</option>
                  <option value="30">30 rows</option>
                  <option value="40">40 rows</option>
                  <option value="50">50 rows</option>
                  <option value="100">100 rows</option>{" "}
                </select>
              </div>

              <ReactPaginate
                {...paginationOptions}
                pageCount={Math.ceil(data.count / queryParams.limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={0}
                onPageChange={({ selected }) => {
                  document.body.scrollTop = document.documentElement.scrollTop = 0;
                  setQueryParams({
                    ...queryParams,
                    page: selected + 1,
                  });
                }}
                forcePage={queryParams.page - 1}
              />
            </div>

            <div
              className={
                btnSummryActive
                  ? `summaryNew border-top open`
                  : `summaryNew border-top`
              }
            >
              <div className="summaryHeader">
                <h2>Summary</h2>
                {btnSummryActive && (
                  <button onClick={() => close()} className="btn p-0">
                    <SummaryCloseIcon />
                  </button>
                )}
              </div>

              <div className="gridCont">
                {/*  <div className="gridChild gridChildBorderLeftBlue">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.totalInvoiceAmt, {
                        symbol: "₦",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Invoice Amt (All)</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div> */}

                <div className="gridChild gridChildBorderLeftOrange">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(
                        filterParams.currency === "NGN" ||
                          !filterParams.currency
                          ? data?.totalNaira
                          : 0,
                        {
                          symbol: "₦",
                        }
                      ).format()}
                    </p>
                    <p className="gridChld2">Total Invoice in Naira (₦)</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftBrown">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(
                        filterParams.currency === "USD" ||
                          !filterParams.currency
                          ? data?.totalDollar
                          : 0,
                        {
                          symbol: "$",
                        }
                      ).format()}
                    </p>
                    <p className="gridChld2">Total Invoice in Dollar ($)</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconDown />
                    <p>5%</p>
                  </div>
                </div>

                {/*    <div className="gridChild gridChildBorderLeftDarkerGreen">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.reversedInvoiceAmount, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Reversed Invoice Amt</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconDown />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftDarkGreen">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.reversedPaymentAmount, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2"> Reversed Payment Amt</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalLoader
        show={isfetchingExcel || addMultipleInvoicesMutation.isLoading}
      />
    </main>
  );
}
