import { useMemo } from "react";
import { NavLink } from "react-router-dom";

export function PlantInventoryNav(props) {
  //   const PlantLocal = JSON.parse(
  //     window.localStorage.getItem("plantDetails")
  //   );

  const items = useMemo(() => {
    return [
      {
        name: "Production Log",
        to: "inventry-entry",
      },
      {
        name: "Receive Item",
        to: "receive-item",
      },
      {
        name: "Item Progress",
        to: "item-progress",
      },
      {
        name: "Item Used",
        to: "item-used",
      },
      {
        name: "Plant Manger",
        to: "plant-history",
      },
      {
        name: "Bill of Materials",
        to: "bill-of-quantity",
      },
      {
        name: "Damages",
        to: "plant-damages",
      },
      {
        name: "Production Plan",
        to: "product-plan",
      },
      {
        name: "Plant Performance",
        to: "plant-performance",
      },
    ];
  }, []);

  return (
    <div className="dashboard-tabs innerpage-tabs px-4">
      <ul className="nav nav-tabs">
        {items.map((item, index) => (
          <li key={index} className="nav-item">
            {Object.entries(item).length > 0 && (
              <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
                {item.name}
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
