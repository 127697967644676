// import { Formik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import {
  QueryClient,
  useQuery,
  useQueryClient,
  QueryCache,
  useMutation,
} from "react-query";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import "./../assets/scss/scoped/login.scoped.scss";
import {
  LoginImageOne,
  LoginImageTwo,
  LogoMeduim,
  OrnamentOne,
  OrnamentTwo,
  SlideShowIndex,
} from "./Icons";
import { useFormik } from "formik";
import { initialGeneralSettings, waitFor } from "../utils/helpers";
import { useAuth } from "../hooks/useAuth";
import { setDefaultLocale } from "react-datepicker";
import { services, backendApis, appSettings, IS_HR } from "../config";
import { useStoreActions } from "easy-peasy";
import { useEffectOnce } from "../utils/hooks";
import { loginHRSite } from "../utils/loginHRSite";
import EyeOffOutlineIcon from "mdi-react/EyeOffOutlineIcon";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";

const options = backendApis.map((el) => ({
  ...el,
  value: el.name,
  label: el.name,
}));

export default function Login() {
  const location = useLocation();
  const { login } = useAuth();
  const queryClient = useQueryClient();
  const setItemMeasurements = useStoreActions(
    (actions) => actions.setItemMeasurements
  );
  const setGeneralSettings = useStoreActions(
    (actions) => actions.setGeneralSettings
  );
  const [showPassword, setShowPassword] = useState(false);

  //const [showAlert, setShowAlert] = useState(true);
  const initialValues = {
    username: "",
    password: "",
    company: "",
    rememberMe: true,
  };
  const schema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required(),
    company: yup.string().required(),
  });

  const loginUser = async (values) => {
    // await waitFor(5000);
    const backendApi = backendApis.find(
      (el) => el.name === formik.values.company
    );
    let response = await fetch(`${backendApi.url}/api/auth/login`, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      body: JSON.stringify({
        ...formik.values,
        adminOnly: appSettings.adminOnly,
      }),
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      if (response.errors) formik.setErrors(response.errors);
      throw new Error(response.message);
    }

    return await response.json();
  };

  const { status, error, mutate, isLoading } = useMutation(
    ["LOGIN"],
    (values) => loginUser(values),
    {
      enabled: false,
      // cacheTime: 0,
      onSuccess: (data) => {
        queryClient.removeQueries("LOGIN");
        login({ ...data.user, company: formik.values.company }, location);
        const backendApi = backendApis.find(
          (el) => el.name === formik.values.company
        );

        data.user.password = formik.values.password;
        IS_HR && loginHRSite({ user: data.user, backendApi });
        setItemMeasurements(data.itemMeasurements);
        setGeneralSettings({ ...initialGeneralSettings, ...data.settings });
      },
    }
  );

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      /* login(
        {
          ...values,
          company: formik.values.company,
          Department: "Admin",
        },
        location
      ); */
      mutate(values);
    },
  });

  useEffectOnce(() => {
    if (options && options.length === 1) {
      formik.setFieldValue("company", options[0].value);
    }
  });

  return (
    <div className="login">
      <div className="form-area">
        <Link to={"/dashboard"} className="logo">
          <LogoMeduim />
        </Link>

        <div className="content">
          {/*  <h1>Sign in</h1> */}
          <div className="text-center">
            <img
              src={`${options[0].url}/images/company-logo.png`}
              alt="Company Logo"
              width={200}
              className="mb-2"
            />
            <p>Welcome back! Please enter your details.</p>
          </div>
          {!isLoading &&
            (status === "error" && error?.message ? (
              <Alert variant="danger">{error?.message}</Alert>
            ) : status === "success" ? (
              <Alert variant="success">Success</Alert>
            ) : null)}

          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label className="login-label">Username</Form.Label>
              <Form.Control
                className="ga-form-control"
                type="text"
                placeholder="Enter your username"
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                isInvalid={formik.touched.username && !!formik.errors.username}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.username}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 position-relative">
              <Form.Label className="login-label">Password</Form.Label>
              <Form.Control
                className="ga-form-control"
                type={showPassword ? "text" : "password"}
                placeholder="••••••••"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                isInvalid={formik.touched.password && !!formik.errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.password}
              </Form.Control.Feedback>
              <Button
                variant=""
                type="button"
                style={{
                  position: "absolute",
                  right: 0,
                  top: "2rem",
                  transform: `scale(0.8)`,
                }}
                onClick={(e) => {
                  e.target.blur();
                  setShowPassword(!showPassword);
                }}
                className="no-focus text-light"
              >
                {!showPassword ? <EyeOffOutlineIcon /> : <EyeOutlineIcon />}
              </Button>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="company" className="login-label">
                Company
              </Form.Label>
              <Select
                id="company"
                placeholder="Select"
                menuShouldScrollIntoView={false}
                className={
                  formik.touched.company && !!formik.errors.company
                    ? "is-invalid"
                    : ""
                }
                classNamePrefix={`select-company`}
                isSearchable={false}
                value={options.find((el) => el.value === formik.values.company)}
                onChange={(selected) => {
                  formik.setFieldValue("company", selected.value, true);
                }}
                onBlur={() => formik.setFieldTouched("company", true)}
                options={options}
              />
              {formik.touched.company && !!formik.errors.company ? (
                <span className="text-danger mt-2">
                  {formik.errors.company}
                </span>
              ) : null}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check
                type={"checkbox"}
                id={`default`}
                label={`Remember me`}
                // value={formik.values.remeberMe}
                checked={formik.values.rememberMe}
                onChange={formik.handleChange}
              />
            </Form.Group>

            <Button
              variant="primary"
              className="w-100 p-2"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? "Please wait…" : "Sign in"}
            </Button>
          </Form>
        </div>

        <a
          className="owner"
          href="https://excellentbridge.com"
          target="_blank"
          rel="noreferrer"
        >
          ExcellentBridge Technologies - version {process.env.REACT_APP_VERSION}
        </a>
      </div>
      <div className="bg-primary slide-show">
        <OrnamentTwo className="ornament-two" />
        <OrnamentOne className="ornament-one" />

        <div className="image">
          {/*   <div className="slide-image-holder">{<LoginImageOne />}</div> */}
          <div className="slide-image-holder-two">
            <LoginImageTwo />
          </div>
          <div>
            <h2 className="text-white">Access insightful data</h2>
            <p>
              Get direct access to key information with customizable dashboard.{" "}
              <br /> Powerful tools for data and analytics, integration and AI.
            </p>
          </div>

          <SlideShowIndex />
        </div>
      </div>
    </div>
  );
}
