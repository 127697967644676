import { useState } from "react";

import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Modal, Table, Form, Button, InputGroup, Row } from "react-bootstrap";
import { appSettings, durationOptions, services } from "../../config";
import useDebounce, { useIsAdmin } from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/item-select-modal.scss";
import { useQuery } from "react-query";
import currency from "currency.js";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import NumberCustomInput from "../utils/NumberCustomInput";
import queryString from "query-string";

import { useFormik, getIn } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { NoSelectedItemIcon } from "../Icons";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useRef } from "react";
import {
  amountTypes,
  convertFromBaseCurrency,
  paginationOptions,
  pcsToTons,
  qtyFormat,
  qtyFormatToString,
  scrollToElement,
  tonsToPcs,
  toTonsOrPcs,
  unitsResolver,
} from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import { useMemo } from "react";
import { useAuth } from "../../hooks/useAuth";
import { isEmpty, lowerCase } from "lodash";
import { useStoreActions, useStoreState } from "easy-peasy";
import ReactPaginate from "react-paginate";
import ModalLoader from "../utils/ModalLoader";
import { truncate } from "lodash";

export default function AddRodItemModal({
  showItemSelectorModal,
  setShowItemSelectorModal,
  handleAddItem,
  saleTypes = [],
  selectedCustomer,
  withQuantity = false,
  conversionAmount,
  currencySymbol = "",
  currencyText = "NGN",
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 100,
    q: "",
    batch: true,
    batchData: true,
    withQuantity: false,
  });
  const debouncedqueryParams = useDebounce(queryParams, 500);
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl } = useAuth();
  const [unitPriceInTons, setUnitPriceInTons] = useState(0);
  const [unitPriceInPcs, setUnitPriceInPcs] = useState(0);
  const generalSettings = useStoreState((state) => state.generalSettings);
  const isAdmin = useIsAdmin();

  const formik = useFormik({
    initialValues: {
      Quantity: 1,
      UnitPrice: "",
      PriceSold: "",
      durationNumber: 0,
      durationValue: "Month",
      Warranty: false,
      Discount: 0,
      ironRodQuantity: 1,
      saleType: "Tons",
      requirePermission: false,
      Size: "",
      discountType: "Fixed",
      discountPercentage: 0,
    },
    validationSchema: yup.object().shape({
      ironRodQuantity: yup
        .number()
        .required("required")
        .moreThan(0, "must be greater than 0"),
      UnitPrice: yup.number().required(),
      PriceSold: yup.number().required(),
    }),
    onSubmit: async (values) => {
      //   console.log(values, selectedItem);

      /* if (values.saleType === "tons") {
        const quantity = tonsToPcs(values.Quantity, selectedItem.Item_Desc);
        if (Number(quantity) > selectedItem?.quantityInStock) {
          return formik.setFieldError(
            "ironRodQuantity",
            `Not enough item in stock`
          );
        }
      } else { */

      const hasNoStock =
        Number(values.Quantity) > selectedItem?.quantityInStock;

      if (
        lowerCase(selectedItem.Item_Type) !== "service" &&
        !generalSettings.invoiceNoStockItem
      ) {
        if (hasNoStock) {
          return formik.setFieldError(
            "ironRodQuantity",
            `Not enough item in stock`
          );
        }

        // check if they have enough sizes in stock
        if (values?.Size && appSettings.hasSize) {
          const selectedSize = selectedItem.sizes.find(
            (el) => el.Size_Color === values.Size
          );
          if (Number(values.Quantity) > selectedSize.Quantity) {
            return formik.setFieldError(
              "Size",
              `Not enough item in stock for the selected Size - ${values.Size}`
            );
          }
        }
      }

      // }

      // if [ton, others ] or pcs
      const theUnitPrice =
        lowerCase(values.saleType) === "tons" ||
        lowerCase(values.saleType) !== "pieces"
          ? unitPriceInTons
          : unitPriceInPcs;

      if (
        Number(theUnitPrice) > Number(values.PriceSold) &&
        !appSettings.ignoreSalePermissions
      ) {
        if (
          !(await ConfirmDialog({
            description:
              "Selling Price entered will require permission \n Do you wish to continue",
          }))
        ) {
          return;
        } else {
          values.requirePermission = true;
        }
      }

      values.Warrant_Duration = `${values.durationNumber} ${
        values.durationValue
      }`;

      values.PriceSold = currency(values.PriceSold, {
        symbol: "",
        separator: "",
      }).value;

      /* values.defaultSubTotal = currency(values.UnitPrice, {
        symbol: "",
        separator: "",
      })
        .multiply(values.Quantity)
        .format(); */

      values.SubTotal = currency(values.PriceSold, {
        symbol: "",
        separator: "",
      }).multiply(
        lowerCase(values.saleType) === "tons"
          ? pcsToTons(values.Quantity, selectedItem.Item_Desc, itemMeasurements)
          : values.Quantity
      ).value;

      /*  console.log(
        "pcs To Tons",
        values.Quantity,
        selectedItem.Item_Desc,
        pcsToTons(values.Quantity, selectedItem.Item_Desc, itemMeasurements)
      ); */

      values.Discount = currency(values.Discount, {
        symbol: "",
        separator: "",
      }).multiply(
        lowerCase(values.saleType) === "tons"
          ? pcsToTons(values.Quantity, selectedItem.Item_Desc, itemMeasurements)
          : values.Quantity
      ).value;

      // Get Profit Based on batch we are picking from---------------------------------------------------
      const quantityToSell = values.Quantity;
      let remainder = quantityToSell;
      const updatedBatchDataToSave = [];
      let measurement = itemMeasurements.find(
        (measurement) => measurement.Size === selectedItem.Item_Desc
      );

      //  console.log(measurement);

      if (
        lowerCase(values.saleType) !== "tons" &&
        lowerCase(values.saleType) !== "pieces"
      ) {
        measurement = {
          Quantity: 1,
        };
      }

      if (lowerCase(values.saleType) === "tons" && !measurement) {
        return toast.error("This item is not sold in Tons");
      }

      const priceSoldPerUnit =
        lowerCase(values.saleType) === "tons"
          ? currency(values.PriceSold)
              .divide(measurement.Quantity)
              .format()
          : values.PriceSold;

      //   console.log(selectedItem.batches);

      if (lowerCase(selectedItem.Item_Type) !== "service") {
        for (let [index, batch] of selectedItem.batches.entries()) {
          const pcsUnitCost = currency(batch.UnitCost, {
            symbol: "",
            separator: "",
          })
            .divide(measurement.Quantity)
            .format();

          //    console.log(pcsUnitCost, batch.UnitCost, measurement.Quantity);

          if (Number(batch.Quantity) >= Number(remainder)) {
            // means we are at the last
            updatedBatchDataToSave.push({
              unitCost: pcsUnitCost,
              quantity: remainder,
              totalUnitCost: currency(pcsUnitCost, {
                symbol: "",
                separator: "",
              })
                .multiply(remainder)
                .format(),
              totalUnitPrice: currency(priceSoldPerUnit, {
                symbol: "",
                separator: "",
              })
                .multiply(remainder)
                .format(),
              totalMargin: currency(selectedItem?.Margin || 0).multiply(
                remainder
              ).value,
              totalIncentive: currency(selectedItem?.Incentive || 0).multiply(
                remainder
              ).value,
            });

            if (lowerCase(values.saleType) === "pieces") {
              values.UnitCost = pcsUnitCost;
            }
            break;
          } else {
            updatedBatchDataToSave.push({
              unitCost: pcsUnitCost,
              quantity: batch.Quantity,
              totalUnitCost: currency(pcsUnitCost, {
                symbol: "",
                separator: "",
              })
                .multiply(batch.Quantity)
                .format(),
              totalUnitPrice: currency(priceSoldPerUnit, {
                symbol: "",
                separator: "",
              })
                .multiply(batch.Quantity)
                .format(),
              totalMargin: currency(selectedItem?.Margin || 0).multiply(
                batch.Quantity
              ).value,
              totalIncentive: currency(selectedItem?.Incentive || 0).multiply(
                batch.Quantity
              ).value,
            });

            remainder = Number(
              currency(remainder, {
                symbol: "",
                separator: "",
              })
                .subtract(batch.Quantity)
                .format()
            );
          }
        }
      }

      // console.log(updatedBatchDataToSave, "updatedBatchTOSave");

      const totalProfit = updatedBatchDataToSave
        .map(
          (el) =>
            currency(el.totalUnitPrice)
              .subtract(el.totalUnitCost)
              .subtract(el.totalIncentive).value
        )
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b).value,
          0
        );

      values.Profit = totalProfit;
      //-------------------------------------------------------------------------------------------------

      /*  values.Profit = currency(values.PriceSold, {
        symbol: "",
        separator: "",
      })
        .subtract(selectedItem.UnitCost)
        .multiply(
          lowerCase(values.saleType) === "tons"
            ? pcsToTons(
                values.Quantity,
                selectedItem.Item_Desc,
                itemMeasurements
              )
            : values.Quantity
        )
        .format(); */

      //  values.Quantity = parseInt(values.Quantity);

      //---------------------------------
      values.Total_Incentive = updatedBatchDataToSave
        .map((el) => el.totalIncentive)
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b).value,
          0
        );

      //  For Service Overwrite ----------------------------------------------------------
      if (
        lowerCase(selectedItem?.Item_Type) === "service" ||
        (hasNoStock && generalSettings.invoiceNoStockItem)
      ) {
        values.Profit = currency(priceSoldPerUnit)
          .subtract(selectedItem.UnitCost)
          .multiply(values.Quantity).value;

        values.Total_Incentive = currency(selectedItem?.Incentive).multiply(
          values.Quantity
        ).value;
      }

      handleAddItem({
        ...selectedItem,
        Serial_Number: values?.Size ? values.Size : selectedItem.Item_Desc,
        ...values,
      });
    },
  });

  const [selectedItem, setselectedItem] = useState(null);
  //  const [searchString, setSearchString] = useState("");

  // const debouncedSearchString = useDebounce(searchString, 800);

  const getItems = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/items?${queryString.stringify(debouncedqueryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { data = { items: [] }, isFetching } = useQuery(
    [queryActions.GET_ITEMS, debouncedqueryParams],
    () => getItems(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const getBatches = async (selectedItemFromPopover) => {
    try {
      setIsLoading(true);
      let response = await fetch(
        `${backendUrl}/api/items/batches-for-sales/${
          selectedItemFromPopover.Bar_Code
        }`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error();
      } else {
        const {
          data: { batches, sizes },
        } = await response.json();
        handleSelectedItem({ ...selectedItemFromPopover, batches, sizes });
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to load batches, Try again");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectedItem = (item) => {
    //  Convert Item  Values to Dollars
    //  console.log(item, conversionAmount);
    const itemCurrency = /* item?.currency &&   ? item.currency : */ currencyText;
    /*   const itemConversionAmount = item?.conversionAmount
      ? item.conversionAmount
      : conversionAmount;  */

    if (itemCurrency !== "NGN") {
      item = convertFromBaseCurrency({
        data: item,
        conversionAmount: conversionAmount,
      });
    }
    //------------------------------------------------------
    // console.log(item);
    // Check Customer type to get correct unit price
    const transType = lowerCase(selectedCustomer?.TransType);
    item.UnitPrice =
      transType === "distributor"
        ? item.Vat_5
        : ["wholesaler", "wholeseler"].includes(transType)
        ? item.Tax
        : item.UnitPrice;

    item.quantityInStock = item.Quantity;
    setselectedItem({ ...item });

    setUnitPriceInTons(item.UnitPrice);
    setUnitPriceInPcs(item.Tax);

    if (transType === "distributor") {
      setUnitPriceInPcs(item.pcsPriceDistributor);
    }

    const UnitPrice = currency(item.UnitPrice, {
      symbol: "",
      separator: "",
    }).format();

    // formik.setFieldValue("Quantity", 1);
    formik.setFieldValue("UnitPrice", UnitPrice);

    let calculatedDiscount = 0;
    // General Discount
    if (generalSettings.enableGeneralDiscount) {
      calculatedDiscount =
        generalSettings.generalDiscountType === "Percentage"
          ? currency(UnitPrice)
              .multiply(generalSettings.generalDiscountPercentage)
              .divide(100).value
          : generalSettings.generalDiscount;

      formik.setFieldValue("discountType", generalSettings.generalDiscountType);
      formik.setFieldValue(
        "discountPercentage",
        generalSettings.generalDiscountPercentage
      );
    } else {
      // Per Item Discount
      calculatedDiscount =
        item.discountType === "Percentage"
          ? currency(UnitPrice)
              .multiply(item.discountPercentage)
              .divide(100).value
          : item.Discount;

      if (item?.discountType) {
        formik.setFieldValue("discountType", item.discountType);
      }
      if (item?.discountPercentage) {
        formik.setFieldValue("discountPercentage", item?.discountPercentage);
      }
    }

    formik.setFieldValue("Discount", calculatedDiscount);

    if (isAdmin || generalSettings.presetFinalSellingPrice) {
      formik.setFieldValue(
        "PriceSold",
        currency(UnitPrice).subtract(calculatedDiscount).value
      );
    }

    formik.setFieldValue(
      "saleType",
      item?.Product_Model ? item?.Product_Model : "Each"
    );

    setTimeout(() => {
      const el = document.querySelector('input[name="ironRodQuantity"]');
      if (el) {
        el.focus();
        el.select();
        el.scrollIntoView({ behavior: "smooth" });
      }
    }, 50);
  };

  const handleSaleType = (value) => {
    // console.log(value);
    if (lowerCase(value) === "tons") {
      formik.setFieldValue("UnitPrice", unitPriceInTons);
      if (isAdmin || generalSettings.presetFinalSellingPrice) {
        formik.setFieldValue("PriceSold", unitPriceInTons);
      }
    } else {
      formik.setFieldValue("UnitPrice", unitPriceInPcs);
      if (isAdmin || generalSettings.presetFinalSellingPrice) {
        formik.setFieldValue("PriceSold", unitPriceInPcs);
      }
    }
  };

  useEffect(() => {
    const finalSellingPrice = currency(formik.values.UnitPrice, {
      symbol: "",
      separator: "",
    })
      .subtract(formik.values.Discount)
      .format();
    formik.setFieldValue("PriceSold", finalSellingPrice);
  }, [formik.values.Discount]);

  useEffect(() => {
    if (formik.values.discountType === "Percentage") {
      formik.setFieldValue(
        "Discount",
        currency(formik.values.UnitPrice)
          .multiply(formik.values.discountPercentage)
          .divide(100)
      );
    }
  }, [formik.values.discountPercentage]);

  // set quantity
  useEffect(() => {
    // if (selectedItem?.Item_Desc) {
    const quantity =
      lowerCase(formik.values.saleType) === "tons" && selectedItem?.Item_Desc
        ? tonsToPcs(
            formik.values.ironRodQuantity,
            selectedItem.Item_Desc,
            itemMeasurements
          )
        : formik.values.ironRodQuantity;
    formik.setFieldValue("Quantity", quantity);
    //}
  }, [
    formik.values.ironRodQuantity,
    formik.values.saleType,
    selectedItem?.Item_Desc,
    itemMeasurements,
  ]);

  const sizes = useMemo(() => {
    return selectedItem && selectedItem?.sizes
      ? selectedItem?.sizes?.map((el) => ({
          ...el,
          label: `${el.Size_Color} - ${el.Quantity}`,
          value: el.Size_Color,
        }))
      : [];
  }, [selectedItem]);

  return (
    <>
      <Modal
        show={true}
        onHide={() => setShowItemSelectorModal(false)}
        dialogClassName="item-select-modal"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>Add Item</h1>
            <p>Select item and set the pricing details.</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <div className="">
              <h2>Select Item</h2>

              <div className="items-table-area rounded">
                <div className="global-search-area m-3 mx-0">
                  <MagnifyIcon />
                  <input
                    className="form-control search-input"
                    name="q"
                    value={queryParams.q}
                    onChange={(e) =>
                      setQueryParams({
                        ...queryParams,
                        q: e.target.value,
                        page: 1,
                      })
                    }
                    placeholder="Search item..."
                    autoFocus
                    autoComplete="off"
                  />
                </div>

                <div className="content">
                  <Table borderless striped hover className="product-table">
                    <thead className="sticky">
                      <tr>
                        <th scope="col">Bar_Code</th>
                        <th scope="col">Item_Name</th>
                        {/* <th scope="col">Unit_Cost</th>
                      <th scope="col">Unit_Price</th> */}
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody className="blue-hover">
                      {data.items &&
                        data.items.map((el, index) => (
                          <tr
                            className="p-cursor"
                            key={index}
                            onClick={() => {
                              if (!isFetching) {
                                getBatches(el);
                              }
                            }}
                          >
                            <td>{el.Bar_Code}</td>
                            <td>{el.Item_Name}</td>
                            {/* <td>
                            {currency(el.UnitCost, {
                              precision: 2,
                              symbol: "",
                            }).format()}
                          </td>
                          <td>
                            {currency(el.UnitPrice, {
                              precision: 2,
                              symbol: "",
                            }).format()}
                          </td> */}
                            <td>
                              {selectedItem?.Bar_Code === el.Bar_Code ? (
                                <Button variant="primary">Selected</Button>
                              ) : (
                                <Button variant="outline-primary">
                                  Select
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
                <div className="d-flex justify-content-between px-3 my-3 align-items-center pagination">
                  <p className="m-0">
                    Showing {data?.startIndex + 1} to{" "}
                    {data?.endIndex <= data?.count
                      ? data?.endIndex
                      : data?.count}{" "}
                    of {data.count} entries
                  </p>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              </div>
            </div>
            <div className="postion-relative">
              {/*  No item */}
              {!selectedItem && (
                <div className="no-item">
                  <div className="info">
                    <NoSelectedItemIcon />
                    <h2>No Item Selected</h2>
                    <p>
                      Please select an item from the table beside to fill in the{" "}
                      <br />
                      pricing details.
                    </p>
                  </div>
                </div>
              )}
              <h2>Item Details</h2>

              <Form noValidate onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3 row">
                  <Form.Group className="mb-2 col-4">
                    <Form.Label className="slim">Item Name</Form.Label>
                    <p className="selected">
                      {selectedItem?.Item_Name || "..."}{" "}
                    </p>
                  </Form.Group>

                  <div className="col text-nowrap">
                    <Form.Label className="slim">Item Code</Form.Label>
                    <p className="selected">
                      {" "}
                      {selectedItem?.Bar_Code || "..."}
                    </p>
                  </div>

                  <div className="col-4">
                    <Form.Label className="slim">Size/Desc</Form.Label>
                    <p className="selected">
                      {truncate(
                        selectedItem?.Item_Desc || selectedItem?.Product_Model
                      )}
                    </p>
                  </div>

                  <div className="col">
                    <Form.Label className="slim">Quantity in Stock</Form.Label>
                    <p className="selected">
                      {selectedItem?.quantityInStock
                        ? qtyFormatToString(
                            qtyFormat(
                              selectedItem?.quantityInStock,
                              selectedItem.Item_Desc,
                              itemMeasurements
                            )
                          )
                        : "..."}
                    </p>
                  </div>

                  <div className="col-4">
                    {isAdmin || !generalSettings.hideUnitCost ? (
                      <>
                        <Form.Label className="slim">Cost Price</Form.Label>
                        <p className="selected">
                          {currency(selectedItem?.UnitCost, {
                            symbol: "",
                          }).format() || "..."}
                        </p>{" "}
                      </>
                    ) : null}
                  </div>
                </Form.Group>
                <hr />

                <h2 className="pt-0">Pricing Details</h2>

                {isAdmin || !generalSettings.hideUnitPrice ? (
                  <Form.Group className="mb-3 pb-1">
                    <Form.Label title={selectedCustomer?.TransType}>
                      Unit Price
                    </Form.Label>
                    <CurrencyCustomInput
                      currencySymbol={currencySymbol}
                      name="UnitPrice"
                      value={formik.values.UnitPrice}
                      isInvalid={
                        formik.touched.UnitPrice && !!formik.errors.UnitPrice
                      }
                      onValueChange={(value, name) => {
                        // formik.setFieldValue(name, value)
                      }}
                      placeholder="0.00"
                      decimalsLimit={2}
                    />
                    {formik.touched.UnitPrice && !!formik.errors.UnitPrice ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.UnitPrice}
                      </span>
                    ) : null}
                  </Form.Group>
                ) : null}

                {isAdmin || !generalSettings.hideDiscount ? (
                  <>
                    {/*   <Form.Group className="mb-3 pb-1">
                      <Form.Label>
                        Discount <span className="slim">(optional)</span>
                      </Form.Label>
                      <CurrencyCustomInput
                        currencySymbol={currencySymbol}
                        name="Discount"
                        value={formik.values.Discount}
                        onValueChange={(value, name) =>
                          formik.setFieldValue(name, value)
                        }
                        placeholder="0.00"
                        decimalsLimit={2}
                      />
                    </Form.Group> */}

                    <Row>
                      <Form.Group className="col-md-4 mb-3 pb-2">
                        <Form.Label className="mb-1">Discount Type</Form.Label>
                        <Select
                          classNamePrefix={"form-select"}
                          options={amountTypes}
                          value={amountTypes.find(
                            (el) => el.value === formik.values.discountType
                          )}
                          onChange={(selected) => {
                            formik.setFieldValue(
                              "discountType",
                              selected.value
                            );
                            if (selected.value === "None") {
                              formik.setFieldValue("discountPercentage", 0);
                              formik.setFieldValue("Discount", 0);
                            }
                          }}
                        />
                      </Form.Group>

                      {formik.values.discountType === "Percentage" && (
                        <Form.Group className="col-md-3 mb-3 pb-2">
                          <Form.Label className="mb-1">Discount (%)</Form.Label>
                          <Form.Control
                            type="number"
                            name="discountPercentage"
                            value={formik.values.discountPercentage}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      )}
                      <Form.Group className="col-md-5 mb-3 pb-2">
                        <Form.Label className="mb-1">Discount</Form.Label>
                        <CurrencyCustomInput
                          name="Discount"
                          placeholder="0.00"
                          value={formik.values.Discount}
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                          isInvalid={
                            formik.touched.Discount && !!formik.errors.Discount
                          }
                          onBlur={() =>
                            formik.setFieldTouched("Discount", true)
                          }
                        />
                      </Form.Group>
                    </Row>
                  </>
                ) : null}

                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Final Selling price</Form.Label>
                  <CurrencyCustomInput
                    currencySymbol={currencySymbol}
                    name="PriceSold"
                    placeholder="0.00"
                    decimalsLimit={2}
                    value={formik.values.PriceSold}
                    onValueChange={(value, name) =>
                      formik.setFieldValue(name, value)
                    }
                  />
                  {formik.touched.PriceSold && !!formik.errors.PriceSold ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.PriceSold}
                    </span>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3 pb-2">
                  <Form.Label>Quantity</Form.Label>
                  <div className="duration">
                    <div>
                      <NumberCustomInput
                        placeholder="0"
                        name="ironRodQuantity"
                        value={formik.values.ironRodQuantity}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value, true);
                        }}
                        isInvalid={
                          formik.touched.ironRodQuantity &&
                          !!formik.errors.ironRodQuantity
                        }
                        onBlur={() =>
                          formik.setFieldTouched("ironRodQuantity", true)
                        }
                        allowNegativeValue={false}
                        // allowDecimals={false}
                      />
                      {formik.touched.ironRodQuantity &&
                      !!formik.errors.ironRodQuantity ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.ironRodQuantity}
                        </span>
                      ) : null}
                    </div>

                    <Select
                      classNamePrefix={"form-select"}
                      placeholder={"Select"}
                      isSearchable={false}
                      value={saleTypes.find(
                        (el) => el.value === formik.values.saleType
                      )}
                      options={unitsResolver(saleTypes, formik.values.saleType)}
                      onChange={({ value }) => {
                        formik.setFieldValue("saleType", value);
                        handleSaleType(value);
                      }}
                    />
                  </div>
                </Form.Group>

                {appSettings.hasSize ? (
                  <Form.Group className="mb-3 pb-1">
                    <Form.Label>Size / Color</Form.Label>
                    <Select
                      classNamePrefix={"form-select"}
                      name="Size"
                      options={sizes}
                      value={sizes.find(
                        (el) => el.value === formik.values.Size
                      )}
                      onChange={({ value }) => {
                        formik.setFieldValue("Size", value);
                      }}
                    />
                    {formik.touched.Size && !!formik.errors.Size ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.Size}
                      </span>
                    ) : null}
                  </Form.Group>
                ) : null}

                {/*     <Form.Check
                type="switch"
                id="custom-switch"
                label="Enable warranty"
                className="mb-3"
                name="Warranty"
                checked={formik.values.Warranty}
                onChange={formik.handleChange}
              /> */}

                <Form.Group className="mb-3 pb-2">
                  <Form.Label>Set Duration</Form.Label>

                  <div className="duration">
                    <NumberCustomInput
                      placeholder="0"
                      name="durationNumber"
                      value={formik.values.durationNumber}
                      onValueChange={(value, name) => {
                        formik.setFieldValue(name, value, true);
                      }}
                      // isInvalid={formik.touched.Quantity && !!formik.errors.Quantity}
                    />

                    <Select
                      classNamePrefix={"form-select"}
                      placeholder={"Month"}
                      value={durationOptions.find(
                        (el) => el.value === formik.values.durationValue
                      )}
                      options={durationOptions}
                      onChange={({ value }) =>
                        formik.setFieldValue("durationValue", value)
                      }
                    />
                  </div>
                </Form.Group>

                <Button
                  disabled={!selectedItem}
                  type="submit"
                  className="w-100 submit-btn"
                >
                  Add to Cart
                </Button>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ModalLoader
        show={isLoading}
        onExited={() => scrollToElement('input[name="ironRodQuantity"]')}
      />
    </>
  );
}
