import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";
import Datetime from "react-datetime";
import { Popover } from "react-tiny-popover";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import { convertMomentDate } from "../../utils/helpers";

const ReceiveInventoryTable = (props) => {
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState(0);
  const [inputBatch, setInputBatch] = useState("");
  const [inputDate, setInputDate] = useState(new Date());
  const [active, setActive] = useState(null);
  const [datePopoverOpened, setDatePopoverOpened] = useState(false);

  const update = (e) => {
    if (e.key === "Enter") {
      if (parseInt(inputValue) < 1)
        return toast.error(`Quantity can not be less than one`);
      if (parseInt(inputValue) > parseInt(props.el.Quantity))
        return toast.error(`Input a lower quantity`);
      setShowInput(false);

      props.updateQuantity(inputValue, active);
    }
  };

  const batchUpdate = (e) => {
    if (e.key === "Enter") {
      if (inputBatch === "") return toast.error(`Input a lower quantity`);

      props.updateBatch(inputBatch, active);
    }
  };

  //

  useEffect(() => {
    setInputValue(props.el.Quantity);
  }, [props.el.Quantity]);

  useEffect(() => {
    setActive((prev) =>
      props?.selectedItems?.find(
        (it) =>
          it?.Bar_Code === props?.el?.Bar_Code &&
          it?.Trans_ID === props.el.Trans_ID
      )
    );
  }, [props.el.Bar_Code, props.el.Trans_ID, props.selectedItems]);

  return (
    <tr>
      <td>
        <input
          className="form-check-input"
          type="checkbox"
          onChange={() => props.onChangeSelected(props.el)}
          checked={active && props.el.Status !== "Rejected"}
          id={props.index}
          disabled={props.el.Status === "Rejected"}
        />

        <label className="form-check-label px-3" htmlFor={props.index}>
          {props.el.Bar_Code}
        </label>
      </td>
      <td onClick={() => setShowInput(false)}>{props.el.Item_Name}</td>
      <td onClick={() => setShowInput((prev) => (active ? true : false))}>
        {showInput ? (
          <input
            type="number"
            className="inputTable"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => update(e, props.el.Bar_Code, props.el.Trans_ID)}
            disabled={props.selectedItems.length < 1 || !active}
          />
        ) : (
          inputValue
        )}
      </td>

      <td onClick={() => setShowInput((prev) => (active ? true : false))}>
        <input
          type="text"
          className="inputBatch"
          value={inputBatch}
          onChange={(e) => setInputBatch(e.target.value)}
          onKeyDown={(e) =>
            batchUpdate(e, props.el.Bar_Code, props.el.Trans_ID)
          }
          // disabled={props.selectedItems.length < 1 || !active}
        />
      </td>

      <td>
        <Popover
          reposition={false}
          isOpen={datePopoverOpened}
          onClickOutside={() => setDatePopoverOpened(false)}
          align="end"
          padding={10}
          content={() => (
            <Datetime
              dateFormat="MM DD, YYYY"
              className="date-picker-2"
              timeFormat={false}
              closeOnSelect={true}
              closeOnClickOutside={true}
              name="supplyDate"
              inputProps={{
                className: `date-input form-control`,
                placeholder: "Select date",
                readOnly: true,
              }}
              input={false}
              value={inputDate}
              onChange={(date) => {
                props.updateDate(date, active);
                setInputDate(date);
                setDatePopoverOpened(false);
              }}
            />
          )}
        >
          <DatePickerCustomInput
            onClick={() => setDatePopoverOpened(!datePopoverOpened)}
            value={convertMomentDate(inputDate)}
          />
        </Popover>
      </td>

      <td>
        {
          <div
            className={`${
              props?.el?.Status === "Rejected"
                ? "view_status_reject"
                : props?.el?.Status === "Partly Received"
                ? "view_status_partly"
                : props?.el?.Status === "Received All"
                ? "view_status_all"
                : props?.el?.Status === "Retured"
                ? "view_status_returned"
                : props?.el?.Status === null
                ? "view_status_pending"
                : ""
            }`}
          >
            {props?.el?.Status !== null ? props?.el?.Status : "Pending"}
          </div>
        }
      </td>
      <td onClick={() => setShowInput(false)}>{props.el.Vendor}</td>
      <td>{format(new Date(props?.el?.Date_Log), "yyyy-MM-dd")}</td>
    </tr>
  );
};

export default ReceiveInventoryTable;
