import { Table, Pagination, Button, Form } from "react-bootstrap";
import {
  CalendarIcon,
  CashSelectIcon,
  ChequeSelectIcon,
  NoSelectedItemIcon,
  PrintIcon,
  CreditMemoSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import DateRangePicker from "../utils/DateRangePicker";
import CachedIcon from "mdi-react/CachedIcon";
import {
  FilterTwoIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
} from "../Icons";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import { reportActions } from "../../utils/reactQueryActions";
import { useBackendUrl, useQueryParams, useScrollTop } from "../../utils/hooks";
import queryString from "query-string";
import { services } from "../../config";
import { useMutation, useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import { paginationOptions } from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
import AccountTransferModal from "../SalesAndInvoicing/AccountTransferModal";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import AddAccounts from "../modals/AddAccounts";
import Modal from "react-bootstrap/Modal";
import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Select from "react-select";
import Datetime from "react-datetime";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";

function CreateNewAccount({ refetch, usage }) {
  const [showCreateNewAccountModal, setShowCreateNewAccountModal] = useState(
    false
  );
  return (
    <>
      <Button
        onClick={() => setShowCreateNewAccountModal(true)}
        className="px-3"
        variant="outline-primary"
      >
        Create New
      </Button>
      {showCreateNewAccountModal && (
        <AddAccounts
          show={showCreateNewAccountModal}
          onHide={() => setShowCreateNewAccountModal(false)}
          refetch={() => refetch()}
          usage={usage}
        />
      )}
    </>
  );
}

export default function Cashes() {
  const { backendUrl } = useAuth();
  useScrollTop();
  const [isAccountTransferOpen, setIsAccountTransferOpen] = useState(false);
  const [btnSummaryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [showDepositToBankModal, setShowDepositToBankModal] = useState(false);

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const initialFilterParams = {
    // startDate: format(new Date(), "yyyy-MM-dd"),
    //  endDate: format(new Date(), "yyyy-MM-dd"),
    enableDateRange: true,
  };
  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const fetchCashbook = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/journal/cashes-report?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    data = { cash: [], bank: [], imprest: [], dollarBank: [] },
    refetch,
    isFetching,
  } = useQuery(
    [reportActions.CASH_BOOK, queryParams],
    () => fetchCashbook(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const cashEndingBalance = useMemo(() => {
    const total = data?.cash
      .map((el) => el.RunningBalanceCalculated)
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );

    return currency(total, {
      symbol: "",
      separator: "",
    }).format();
  }, [data?.cash]);

  const bankEndingBalance = useMemo(() => {
    const total = data?.bank
      .map((el) => el.RunningBalanceCalculated)
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );

    return currency(total, {
      symbol: "",
      separator: "",
    }).format();
  }, [data?.bank]);

  const dollarBankEndingBalance = useMemo(() => {
    const total = data?.dollarBank
      .map((el) => el.RunningBalanceCalculated)
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );

    return currency(total, {
      symbol: "",
      separator: "",
    }).format();
  }, [data?.dollarBank]);

  const imprestEndingBalance = useMemo(() => {
    const total = data?.imprest
      .map((el) => el.RunningBalanceCalculated)
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );

    return currency(total, {
      symbol: "",
      separator: "",
    }).format();
  }, [data?.imprest]);

  //------------

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setQueryParams({
      ...queryParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setQueryParams({
      ...queryParams,
      startDate: "",
      endDate: "",
    });
  };

  return (
    <main className="cash-book">
      <div className="content">
        <header>
          <h1>
            Cash/Bank Balances
            <button onClick={() => refetch()} className="btn text-primary">
              <CachedIcon />
            </button>
          </h1>
          <div className="actions mr-5">
            {/*  <DateRangePicker
              apply={(date) => filterByDateRange(date)}
              defaultValue={
                queryParams.startDate && queryParams.endDate
                  ? [
                      parse(queryParams.startDate, "yyyy-MM-dd", new Date()),
                      parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                    ]
                  : []
              }
            /> */}
            <RsDateRangePicker
              placement="bottomEnd"
              defaultValue={
                queryParams.startDate && queryParams.endDate
                  ? [
                      parse(queryParams.startDate, "yyyy-MM-dd", new Date()),
                      parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                    ]
                  : []
              }
              onClean={() => clearDateRange()}
              onOk={(date) => filterByDateRange(date)}
            />

            <button
              className="btn btn-primary"
              onClick={() => setIsAccountTransferOpen(true)}
            >
              Cash Transfer and Lodgement
            </button>
            {/*  <button
              onClick={() => setBtnSummaryActive(!btnSummaryActive)}
              className="btn summaryBtn "
            >
              Show Summary
            </button> */}
          </div>
        </header>

        <div className="px-md-4" />
        <div
          className={
            btnSummaryActive
              ? `summaryNew -border-top open`
              : `summaryNew -border-top`
          }
        >
          <div className="summaryHeader">
            {/*  <h2>Summary</h2> */}
            <div />
            {btnSummaryActive && (
              <button onClick={() => close()} className="btn p-0">
                <SummaryCloseIcon />
              </button>
            )}
          </div>

          <div className="row justify-content-between">
            <div className="col">
              <div className="mb-3">
                <div className="d-flex gap-3 align-items-center mb-4">
                  <h2 className="m-0">Cash on hand</h2>{" "}
                  <hr className="flex-grow-1 m-0" />
                </div>

                <div
                  className="gridCont mb-3"
                  style={{ gridTemplateRows: "unset" }}
                >
                  {data.cash.map((el, index) => (
                    <Link
                      to={`/account-setup/account-list?&Description=${
                        el.Description
                      }&AccountID=${el.AccountID}`}
                      key={index}
                      className="gridChild gridChildBorderLeftDarkPurple"
                    >
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(el.RunningBalanceCalculated, {
                            symbol: "₦",
                          }).format()}
                        </p>
                        <p className="gridChld2">
                          {el.Description === "Cash on hand"
                            ? "Cash on hand - Ending balance"
                            : el.Description}
                        </p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </Link>
                  ))}
                </div>

                {isEmpty(data.cash) && !isFetching ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      <NoSelectedItemIcon />
                      <h2 className="m-3">None Found</h2>
                      <CreateNewAccount
                        refetch={refetch}
                        usage={"cash on hand"}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="w-100 d-flex align-items-center justify-content-end my-3 mb-5 p-3 gap-4">
                  <h2 className="m-0">Ending Balance:</h2>
                  <div className="border-top border-bottom p-3">
                    <p className="h5 m-0 pe-4">
                      {currency(cashEndingBalance, {
                        symbol: "₦",
                      }).format()}
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="d-flex gap-3 align-items-center mb-4">
                  <h2 className="m-0">Imprest</h2>{" "}
                  <hr className="flex-grow-1 m-0" />
                </div>

                <div
                  className="gridCont mb-3"
                  style={{ gridTemplateRows: "unset" }}
                >
                  {data.imprest.map((el, index) => (
                    <Link
                      to={`/account-setup/account-list?&Description=${
                        el.Description
                      }&AccountID=${el.AccountID}`}
                      key={index}
                      className="gridChild gridChildBorderLeftLightYellow"
                    >
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(el.RunningBalanceCalculated, {
                            symbol: "₦",
                          }).format()}
                        </p>
                        <p className="gridChld2">{el.Description}</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </Link>
                  ))}
                </div>

                {isEmpty(data.imprest) && !isFetching ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      <NoSelectedItemIcon />
                      <h2 className="m-3">None Found</h2>
                      <CreateNewAccount refetch={refetch} usage={"imprest"} />
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="w-100 d-flex align-items-center justify-content-end my-3 mb-5 p-3 gap-4">
                  <h2 className="m-0">Ending Balance:</h2>
                  <div className="border-top border-bottom p-3">
                    <p className="h5 m-0 pe-4">
                      {currency(imprestEndingBalance, {
                        symbol: "₦",
                      }).format()}
                    </p>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="d-flex gap-3 align-items-center mb-4">
                  <h2 className="m-0">Bank (NGN)</h2>{" "}
                  <hr className="flex-grow-1 m-0" />
                </div>

                <div
                  className="gridCont mb-3"
                  style={{ gridTemplateRows: "unset" }}
                >
                  {data.bank.map((el, index) => (
                    <Link
                      to={`/account-setup/account-list?&Description=${
                        el.Description
                      }&AccountID=${el.AccountID}`}
                      key={index}
                      className="gridChild gridChildBorderLeftOrange"
                    >
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(el.RunningBalanceCalculated, {
                            symbol: "₦",
                          }).format()}
                        </p>
                        <p className="gridChld2">{el.Description}</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </Link>
                  ))}
                </div>

                {isEmpty(data.bank) && !isFetching ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      <NoSelectedItemIcon />
                      <h2 className="m-3">None Found</h2>
                      <CreateNewAccount refetch={refetch} usage={"bank"} />
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="w-100 d-flex align-items-center justify-content-end my-3 mb-5 p-3 gap-4">
                  <h2 className="m-0">Ending Balance:</h2>
                  <div className="border-top border-bottom p-3">
                    <p className="h5 m-0 pe-4">
                      {currency(bankEndingBalance, {
                        symbol: "₦",
                      }).format()}
                    </p>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="d-flex gap-3 align-items-center mb-4">
                  <h2 className="m-0">Bank (USD)</h2>{" "}
                  <hr className="flex-grow-1 m-0" />
                  <Button onClick={() => setShowDepositToBankModal(true)}>
                    USD Deposit or Withdrawal
                  </Button>
                </div>

                <div
                  className="gridCont mb-3"
                  style={{ gridTemplateRows: "unset" }}
                >
                  {data.dollarBank.map((el, index) => (
                    <Link
                      to={`/bank-account-analysis?startDate=&endDate=&BankName=${
                        el.Description
                      }&selectedBankCurrency=USD`}
                      key={index}
                      className="gridChild gridChildBorderLeftDarkGreen"
                    >
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(el.RunningBalanceCalculated, {
                            symbol: "$",
                          }).format()}
                        </p>
                        <p className="gridChld2">{el.Description}</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </Link>
                  ))}
                </div>

                {isEmpty(data.dollarBank) && !isFetching ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      <NoSelectedItemIcon />
                      <h2 className="m-3">None Found</h2>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="w-100 d-flex align-items-center justify-content-end my-3 mb-5 p-3 gap-4">
                  <h2 className="m-0">Ending Balance:</h2>
                  <div className="border-top border-bottom p-3">
                    <p className="h5 m-0 pe-4">
                      {currency(dollarBankEndingBalance, {
                        symbol: "$",
                      }).format()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isAccountTransferOpen && (
        <AccountTransferModal
          setShowAccountTransferModal={setIsAccountTransferOpen}
          refetch={refetch}
        />
      )}

      {showDepositToBankModal && (
        <DepositToBankModal
          showDepositToBankModal={showDepositToBankModal}
          setShowDepositToBankModal={setShowDepositToBankModal}
          refetch={refetch}
        />
      )}
    </main>
  );
}

function DepositToBankModal({
  showDepositToBankModal,
  setShowDepositToBankModal,
  refetch,
}) {
  const paymentMethod = [
    {
      icon: <CashSelectIcon />,
      label: "Cash",
      value: "Cash",
    },

    {
      icon: <ChequeSelectIcon />,
      label: "Cheque",
      value: "Cheque",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit/Debit Card (POS)",
      value: "Credit/Debit Card",
    },

    {
      icon: <CreditMemoSelectIcon />,
      label: "Credit Memo",
      value: "Credit Memo",
    },
    {
      icon: <DirectTransferSelectIcon />,
      label: "Direct Bank Transfer",
      value: "Direct Bank Transfer",
    },
    /* {
      icon: <CashSelectIcon />,
      label: "Split Payment",
      value: "Split Payment",
    }, */
  ];

  const bankActions = [
    {
      label: "Deposit",
      value: "Deposit",
    },
    {
      label: "Withdrawal",
      value: "Withdrawal",
    },
  ];

  const backendUrl = useBackendUrl();
  const [isLoading, setIsLoading] = useState(false);
  const [banks, setBanks] = useState([]);

  const initialValues = {
    bank: "",
    newInstallment: 0,
    salesDate: new Date(),
    bankAction: "Deposit",
    PaymentType: "",
  };
  const schema = yup.object().shape({
    bank: yup.string().required(),
  });

  const getBanks = async () => {
    try {
      setIsLoading(true);
      let response = await fetch(`${backendUrl}/api/users/banks?currency=USD`, {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        response = await response.json();
        toast.error(response.message);
      } else {
        const {
          data: { banks = [] },
        } = await response.json();
        setBanks(
          banks.map((el) => ({
            label: `${el.BankName} ${el?.currency ? `(${el?.currency})` : ``}`,
            value: el.BankName,
          }))
        );
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to get banks");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBanks();
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      depositToBankMutation.mutate(values);
    },
  });

  const depositToBank = async (payload) => {
    let response = await fetch(`${backendUrl}/api/journal/transfer-to-bank`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const depositToBankMutation = useMutation(
    (payload) => depositToBank(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        formik.resetForm();
        if (refetch) refetch();
        setShowDepositToBankModal(false);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  return (
    <>
      <Modal
        show={showDepositToBankModal}
        onHide={() => setShowDepositToBankModal(false)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Deposit</Modal.Title>
        </Modal.Header>{" "}
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>{"  "}</Form.Label>
              <Select
                classNamePrefix="form-select"
                placeholder="Select"
                disabled={isLoading}
                isSearchable={false}
                options={bankActions}
                value={bankActions.find(
                  (el) => el.value === formik.values.bankAction
                )}
                onChange={({ value }) =>
                  formik.setFieldValue("bankAction", value)
                }
                onBlur={() => formik.setFieldTouched("bankAction", true)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Bank</Form.Label>
              <Select
                classNamePrefix="form-select"
                placeholder="Select"
                disabled={isLoading}
                isSearchable={false}
                options={banks}
                value={banks.find((el) => el.value === formik.values.bank)}
                onChange={({ value }) => formik.setFieldValue("bank", value)}
                className={
                  formik.touched.bank && !!formik.errors.bank
                    ? "is-invalid"
                    : ""
                }
              />
              {formik.touched.bank && formik.errors.bank ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.bank}
                </span>
              ) : null}
            </Form.Group>

            <Form.Group className="mb-3 pb-1">
              <Form.Label>Payment Type</Form.Label>
              <Select
                classNamePrefix="form-select"
                menuPlacement="bottom"
                menuPosition="fixed"
                placeholder="Choose method"
                isSearchable={false}
                value={paymentMethod.find(
                  (el) => el.value === formik.values.PaymentType
                )}
                options={paymentMethod}
                onChange={({ value }) =>
                  formik.setFieldValue("PaymentType", value)
                }
                getOptionLabel={(el) => (
                  <div className="label-with-icon d-flex gap-2 align-items-center">
                    <span>{el.icon}</span>{" "}
                    <span className="fw-5">{el.label}</span>
                  </div>
                )}
              />
            </Form.Group>

            <Form.Group className="mb-3 pb-1">
              <Form.Label>Amount</Form.Label>
              <CurrencyCustomInput
                currencySymbol={"$"}
                name="newInstallment"
                placeholder="0.00"
                decimalsLimit={2}
                value={formik.values.newInstallment}
                onValueChange={(value, name) =>
                  formik.setFieldValue(name, value)
                }
                isInvalid={
                  formik.touched.newInstallment &&
                  !!formik.errors.newInstallment
                }
                onBlur={() => formik.setFieldTouched("newInstallment", true)}
              />
              {formik.touched.newInstallment &&
              !!formik.errors.newInstallment ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.newInstallment}
                </span>
              ) : null}
            </Form.Group>

            <Form.Group className="mb-3 pb-1">
              <Form.Label>Remark</Form.Label>
              <Form.Control
                name="remark"
                value={formik.values.remark}
                onChange={formik.handleChange}
                maxLength={50}
              />
            </Form.Group>

            <Form.Group className="mb-3 pb-1">
              <Form.Label>Date</Form.Label>
              <Datetime
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside={true}
                dateFormat="MMM DD, YYYY"
                name="salesDate"
                inputProps={{
                  className: `date-input form-control ${
                    formik.touched.salesDate && !!formik.errors.salesDate
                      ? "is-invalid"
                      : ""
                  }`,
                  placeholder: "Select date",
                  readOnly: true,
                }}
                value={formik.values.salesDate}
                onChange={(date) => {
                  formik.setFieldValue("salesDate", date, true);
                }}
                onBlur={() => formik.setFieldTouched("salesDate", true)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant="secondary "
              onClick={() => setShowDepositToBankModal(false)}
              type="button"
            >
              Close
            </Button>
            <Button
              disabled={isLoading || depositToBankMutation.isLoading}
              type="submit"
              variant="primary"
            >
              {depositToBankMutation.isLoading ? "Please wait..." : " Post"}{" "}
            </Button>
          </Modal.Footer>{" "}
        </Form>
      </Modal>
    </>
  );
}
