import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { useState } from "react";
import { Modal, Table, Dropdown, Button } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../../hooks/useAuth";
import useDebounce, {
  useIsAdmin,
  useIsDocumentControl,
  useIsOperations,
} from "../../utils/hooks";
import {
  ApproveCheckIcon,
  BookIcon,
  DeleteIcon,
  DropdownEyeIcon,
  EditIcon,
} from "../Icons";
import queryString from "query-string";
import {
  formatDate,
  formatNumberSystem,
  paginationOptions,
  slugify,
} from "../../utils/helpers";
import NoTableItem from "../utils/NoTableItem";
import { isEmpty } from "lodash";
import FormPreviewModal from "./FormPreviewModal";
import { toast } from "react-toastify";
import ConfirmDialog from "../ConfirmDialogue";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { parse, format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import ModalLoader from "../utils/ModalLoader";
import CachedIcon from "mdi-react/CachedIcon";
import ReactPaginate from "react-paginate";
import NewCustomRequisitionModal from "./NewCustomRequisitionModal";

export default function FilledForms({
  form,
  setSelectedForm,
  setShowFilledFormsModal,
  Staff_ID,
}) {
  const { backendUrl, user: authUser } = useAuth();
  const isAdmin = useIsAdmin();
  const isOperations = useIsOperations();
  const isDocumentControl = useIsDocumentControl();
  const [selectedFilledForm, setSelectedFilledForm] = useState();
  const [
    showNewCustomRequisitionModal,
    setShowNewCustomRequisitionModal,
  ] = useState();
  const navigate = useNavigate();
  const [showFormPreviewModal, setShowFormPreviewModal] = useState(false);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 40,
    FormID: form.id,
    Staff_ID: Staff_ID ? Staff_ID : "",
  });
  const debouncedqueryParams = useDebounce(queryParams, 500);
  const getForms = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/forms/filled-forms?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const { data = { forms: [] }, isFetched, refetch } = useQuery(
    ["FILLED_FORMS", debouncedqueryParams],
    () => getForms(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      [e.target.name]: e.target.value,
    });
  };

  const preview = (form) => {
    setSelectedFilledForm(form);
    setShowFormPreviewModal(true);
  };

  const deleteApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/forms/delete-filled-form`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const deleteMutation = useMutation((payload) => deleteApi(payload), {
    onSuccess: () => {
      toast.success(` Deleted`);
      refetch();
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });
  const deleteForm = async (form) => {
    if (
      await ConfirmDialog({
        title: "Delete",
        description: "Are you sure, you want to delete this form",
      })
    ) {
      deleteMutation.mutate({
        id: form.id,
      });
    }
  };

  const filterByDateRange = (date) => {
    setQueryParams({
      ...queryParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setQueryParams({
      ...queryParams,
      startDate: "",
      endDate: "",
    });
  };

  const compileFormApi = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/forms/sync-form/${payload.id}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const compileMutation = useMutation((payload) => compileFormApi(payload), {
    onSuccess: ({ compiledForm }) => {
      setSelectedFilledForm(compiledForm);
      setShowFormPreviewModal(true);
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const compileRegister = (form) => {
    compileMutation.mutate(form);
  };

  const sendForApproval = (form) => {};

  return (
    <>
      <Modal
        show={true}
        animation={false}
        centered={true}
        onHide={() => {
          setSelectedForm(null);
          setShowFilledFormsModal(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="h5 mb-0">
              Filled Forms for {form?.Title}{" "}
              {Staff_ID ? `by ${authUser.Name}` : ""}{" "}
              <button
                title="Refresh"
                onClick={() => refetch()}
                className="btn text-primary"
              >
                <CachedIcon />
              </button>
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex gap-3 align-items-center pe-3 mb-3">
            <div className="global-search-area w-75">
              <MagnifyIcon />
              <input
                className="form-control search-input"
                name="q"
                value={queryParams.q}
                onChange={(e) =>
                  setQueryParams({
                    ...queryParams,
                    page: 1,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Search..."
                autoFocus
                autoComplete="off"
                type={"text"}
              />
            </div>

            <RsDateRangePicker
              placement="auto"
              value={
                queryParams.startDate && queryParams.endDate
                  ? [
                      parse(queryParams.startDate, "yyyy-MM-dd", new Date()),
                      parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                    ]
                  : []
              }
              onClean={() => clearDateRange()}
              onOk={(date) => filterByDateRange(date)}
            />
          </div>

          {!isEmpty(data?.forms) && (
            <Table borderless striped responsive className="product-table">
              <thead>
                <tr>
                  <th />
                  <th>S/N</th>
                  <th>Form Number</th>
                  <th>Staff Name</th>
                  <th>Form Name</th>
                  <th>Revision</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {data.forms.map((el, index) => (
                  <tr key={index} className={`p-cursor`}>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          className="bg-white border-0"
                          bsPrefix="print more"
                        >
                          <DotsVerticalIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          popperConfig={{
                            strategy: "fixed",
                          }}
                          renderOnMount
                          className="dropdown-with-icons"
                        >
                          <Dropdown.Item
                            as="button"
                            onClick={() => preview(el)}
                          >
                            <DropdownEyeIcon /> View Form
                          </Dropdown.Item>

                          {isAdmin ||
                          isDocumentControl ||
                          (isOperations && authUser.AccessLavel === "High") ? (
                            <>
                              {form.approvalType ===
                                "Fill and Approval Required" &&
                              !el?.requestid ? (
                                <Dropdown.Item
                                  as="button"
                                  onClick={() => {
                                    setSelectedFilledForm(el);
                                    setShowNewCustomRequisitionModal(true);
                                  }}
                                >
                                  <ApproveCheckIcon /> Send for Approval
                                </Dropdown.Item>
                              ) : null}

                              {el?.requestid ? (
                                <Dropdown.Item
                                  as="button"
                                  onClick={() => {
                                    navigate(
                                      `/requisition/request/preview/${
                                        el?.requestid
                                      }`
                                    );
                                  }}
                                >
                                  <DropdownEyeIcon /> View Request
                                </Dropdown.Item>
                              ) : null}
                            </>
                          ) : null}

                          {form?.register && (isAdmin || isDocumentControl) ? (
                            <Dropdown.Item
                              as="button"
                              onClick={() => {
                                compileRegister(el);
                              }}
                            >
                              <DropdownEyeIcon /> Compile Register
                            </Dropdown.Item>
                          ) : null}

                          {(isAdmin ||
                            isDocumentControl ||
                            (isOperations &&
                              authUser.AccessLavel === "High")) &&
                          !Staff_ID ? (
                            <>
                              <Dropdown.Item as="div">
                                <Link
                                  to={`/edit-company-form/${el.id}/${
                                    authUser.company
                                  }/${slugify(form?.Title)}`}
                                  target="_blank"
                                >
                                  <EditIcon /> Edit
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() => deleteForm(el)}
                                className="text-danger"
                              >
                                <DeleteIcon /> Delete
                              </Dropdown.Item>
                            </>
                          ) : null}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>

                    <td>{index + 1}</td>
                    <td>
                      {formatNumberSystem(
                        el?.formNumberPrefix,
                        el?.currentNumber
                      )}
                    </td>
                    <td>{el?.UserName || "..."}</td>
                    <td>{el?.formName || "..."}</td>
                    <td>{el?.revision}</td>
                    <td className="text-nowrap">
                      {el?.Date_Log ? formatDate(el.Date_Log) : "..."}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {isFetched && isEmpty(data?.forms) ? (
            <NoTableItem queryParams={queryParams} />
          ) : null}

          <div className="d-flex justify-content-between px-3 align-items-center pagination">
            <div className="pagination_left d-flex gap-2 align-items-center">
              <p className="m-0 p-0">Show</p>
              <select
                value={queryParams.limit}
                name="limit"
                className="form-select "
                onChange={(e) => handleSearchQueryChange(e)}
              >
                <option value="10">10 rows</option>
                <option value="20">20 rows</option>
                <option value="30">30 rows</option>
                <option value="40">40 rows</option>
                <option value="40">100 rows</option>
              </select>
            </div>

            <ReactPaginate
              {...paginationOptions}
              pageCount={Math.ceil(data.count / queryParams.limit)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={0}
              onPageChange={({ selected }) => {
                setQueryParams({
                  ...queryParams,
                  page: selected + 1,
                });
              }}
              forcePage={queryParams.page - 1}
            />
          </div>
        </Modal.Body>
      </Modal>

      {showFormPreviewModal && selectedFilledForm ? (
        <FormPreviewModal
          showFormPreviewModal={showFormPreviewModal}
          setShowFormPreviewModal={setShowFormPreviewModal}
          template={selectedFilledForm.filledFormData}
          selectedFilledForm={selectedFilledForm}
          setSelectedFilledForm={setSelectedFilledForm}
        />
      ) : null}

      {showNewCustomRequisitionModal && selectedFilledForm ? (
        <NewCustomRequisitionModal
          template={selectedFilledForm.filledFormData}
          setShowNewCustomRequisitionModal={(isOpen) => {
            setShowNewCustomRequisitionModal(isOpen);
            if (!isOpen) {
              setSelectedFilledForm();
            }
          }}
          sendAfterRequisition={() => {
            refetch();
          }}
          FormID={selectedFilledForm.FormID}
          childFormId={selectedFilledForm.id}
        />
      ) : null}
      <ModalLoader show={compileMutation.isLoading} />
    </>
  );
}
